const swUrl = `${process.env.PUBLIC_URL}/sw.js`;

const isLocalhost = Boolean(
  window.location.hostname === "localhost" ||
  window.location.hostname === "[::1]" ||
  window.location.hostname.match(
    /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
  )
);

export function register(config) {
  window.addEventListener("load", () => {
    if ("serviceWorker" in navigator) {
      registerValidSW(config);
    }
  });
}

export async function unregister() {
  // if ("serviceWorker" in navigator) {
  //   navigator.serviceWorker.getRegistrations().then(function (registrations) {
  //     for (let registration of registrations) {
  //       registration.unregister();
  //     }
  //   });
  // }
  var manifestLink = document.querySelector('link[rel="manifest"]');

  // Check if the link element exists before attempting to remove it
  if (manifestLink) {
    // Remove the link element from the DOM
    manifestLink.parentNode.removeChild(manifestLink);
  }
}

export const handleServiceWorker = () => {
  const usePreferences = window.__E_TT_Client || {};
  const hasAccess = usePreferences && usePreferences.offlineAccess;
  if (hasAccess === String(true)) {
    return registerValidSW();
  } else {
    return unregister();
  }
};

// new for microphone permission

// Check if the user has granted microphone permission
// if ('permissions' in navigator) {
// 	navigator.permissions.query({ name: 'microphone' }).then((permissionStatus) => {
// 		if (permissionStatus.state === 'granted') {
// 			// If the permission has been granted, revoke it
// 			permissionStatus.revoke();
// 		}
// 	});
// }

// remove microphone access permanently
if ("permissions" in navigator) {
  navigator.permissions
    .query({ name: "microphone" })
    .then((permissionStatus) => {
      if (permissionStatus.state === "granted") {
        permissionStatus.status = "denied";
      }
    });
}

// new for microphone permission

function registerValidSW(config) {

  navigator.serviceWorker
    .register(swUrl)
    .then((registration) => {
      registration.onupdatefound = () => {

        const installingWorker = registration.installing;
        if (installingWorker == null) return;

        installingWorker.onstatechange = () => {
          if (installingWorker.state === "installed") {

            if (navigator.serviceWorker.controller) {
              if (config && config.onUpdate) config.onUpdate(registration);
            } else {
              if (config && config.onSuccess) config.onSuccess(registration);
            }
          }
        };
      };
    })
    .catch((error) => {
      console.error("Error during service worker registration:", error);
    });
}
