import React from 'react'
import { APP_DOMAIN } from '../../config'


const notexistImg = '/images/notexist.png'

const DisableHomePage = () => {
    return (
        <div className='disAllowHomePagemain'>
            <div className='disAllowHomePageImgContent'>
                <img src={notexistImg} alt="not exist" />
                <h4>There are no collaterals on this page</h4>
            </div>
        </div>
    )
}

export default DisableHomePage