import React from "react";
import ecollatHelper from "./viewer";
import productHelper from "./product-handler";
import { APP_DOMAIN, ASSETS_URI } from "../config";
import { HOST } from "../config";
import Service from "../Action/service";

const download = "/images/download.svg";
const cart = "/images/cart.svg";
export const SVG = {
  backicon: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <mask
        id="mask0_4333_52243"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="24"
      >
        <rect width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_4333_52243)">
        <path
          d="M10.875 19.2947L4.275 12.6947C4.175 12.5947 4.104 12.4864 4.062 12.3697C4.02067 12.253 4 12.128 4 11.9947C4 11.8614 4.02067 11.7364 4.062 11.6197C4.104 11.503 4.175 11.3947 4.275 11.2947L10.875 4.69471C11.0583 4.51138 11.2873 4.41538 11.562 4.40671C11.8373 4.39871 12.075 4.49471 12.275 4.69471C12.475 4.87804 12.5793 5.10704 12.588 5.38171C12.596 5.65704 12.5 5.89471 12.3 6.09471L7.4 10.9947H18.575C18.8583 10.9947 19.096 11.0904 19.288 11.2817C19.4793 11.4737 19.575 11.7114 19.575 11.9947C19.575 12.278 19.4793 12.5154 19.288 12.7067C19.096 12.8987 18.8583 12.9947 18.575 12.9947H7.4L12.3 17.8947C12.4833 18.078 12.5793 18.3114 12.588 18.5947C12.596 18.878 12.5 19.1114 12.3 19.2947C12.1167 19.4947 11.8833 19.5947 11.6 19.5947C11.3167 19.5947 11.075 19.4947 10.875 19.2947Z"
          fill="#929292"
        />
      </g>
    </svg>
  ),
  lefticon: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <mask
        id="mask0_4323_44353"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="24"
      >
        <rect width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_4323_44353)">
        <path
          d="M13.3008 17.2969L8.70078 12.6969C8.60078 12.5969 8.52995 12.4885 8.48828 12.3719C8.44661 12.2552 8.42578 12.1302 8.42578 11.9969C8.42578 11.8635 8.44661 11.7385 8.48828 11.6219C8.52995 11.5052 8.60078 11.3969 8.70078 11.2969L13.3008 6.69688C13.4841 6.51354 13.7174 6.42188 14.0008 6.42188C14.2841 6.42188 14.5174 6.51354 14.7008 6.69688C14.8841 6.88021 14.9758 7.11354 14.9758 7.39688C14.9758 7.68021 14.8841 7.91354 14.7008 8.09688L10.8008 11.9969L14.7008 15.8969C14.8841 16.0802 14.9758 16.3135 14.9758 16.5969C14.9758 16.8802 14.8841 17.1135 14.7008 17.2969C14.5174 17.4802 14.2841 17.5719 14.0008 17.5719C13.7174 17.5719 13.4841 17.4802 13.3008 17.2969Z"
          fill="#929292"
        />
      </g>
    </svg>
  ),
  righticon: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <mask
        id="mask0_4323_44357"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="24"
      >
        <rect width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_4323_44357)">
        <path
          d="M8.70078 17.2969C8.51745 17.1135 8.42578 16.8802 8.42578 16.5969C8.42578 16.3135 8.51745 16.0802 8.70078 15.8969L12.6008 11.9969L8.70078 8.09688C8.51745 7.91354 8.42578 7.68021 8.42578 7.39688C8.42578 7.11354 8.51745 6.88021 8.70078 6.69688C8.88411 6.51354 9.11745 6.42188 9.40078 6.42188C9.68411 6.42188 9.91745 6.51354 10.1008 6.69688L14.7008 11.2969C14.8008 11.3969 14.8716 11.5052 14.9133 11.6219C14.9549 11.7385 14.9758 11.8635 14.9758 11.9969C14.9758 12.1302 14.9549 12.2552 14.9133 12.3719C14.8716 12.4885 14.8008 12.5969 14.7008 12.6969L10.1008 17.2969C9.91745 17.4802 9.68411 17.5719 9.40078 17.5719C9.11745 17.5719 8.88411 17.4802 8.70078 17.2969Z"
          fill="#1E293B"
        />
      </g>
    </svg>
  ),
};

export const svgIcon = (term, type, btn_size_drag) => {
  if (term === "ico-download-circle" || term === "ico-cart-circle") {
    return (
      <img
        alt="..."
        src={term === "ico-download-circle" ? download : cart}
        title={
          term === "ico-download-circle"
            ? "Download"
            : type === 3
            ? "Add collection to cart"
            : "Add to cart"
        }
      />
    );
  } else {
    return (
      <svg
        className="icon"
        id={`id${term}`}
        width={`${
          btn_size_drag === "0"
            ? "20px"
            : btn_size_drag === "1"
            ? "30px"
            : btn_size_drag === "2"
            ? "40px"
            : "100%"
        }`}
        height={`${
          btn_size_drag == "0"
            ? "20px"
            : btn_size_drag == "1"
            ? "30px"
            : btn_size_drag == "2"
            ? "40px"
            : "100%"
        }`}
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <use id={`id${term}`} xlinkHref={`#${term}`}></use>
        {(term === "ico-download-circle" && <title>Download</title>) ||
          (term === "ico-cart-circle" && <title>Add to cart</title>)}
      </svg>
    );
  }
};

export const isTouchDevice = () => {
  return "ontouchstart" in window || navigator.maxTouchPoints;
};

export const uuidv4 = () => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    //eslint-disable-next-line
    var r = (Math.random() * 16) | 0,
      v = c == "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

export const getImageSrc = function (pageNo, size = 0, pdf = {}) {
  return `${ASSETS_URI}/${window.__E_TT_Client.companySlug}/${pdf.pdfID}/${pageNo}/image-${size}.jpg`;
};

// export const getImagefullSrc = async (pageNo, size = 0, pdf = {}) => {

// 	if (!pdf.pdfID) return false;
// 	try {
// 	  const response = await Service.get(`${HOST}/view-image.dll?id=${pdf.pdfID}&count=${pageNo}&imagesize=${size}`);
// 	  if (response.status === 202) {
// 		const etag = response.headers.etag;
// 		const cachedUrl = localStorage.getItem(etag); // Checking if the ETag is already cached
// 		// if (cachedUrl===etag) {
// 		//   return cachedUrl;
// 		// }
// 		const imageUrl = `${ASSETS_URI}/${response.data.data}`;
// 		localStorage.setItem(etag, imageUrl); // Caching the ETag with the corresponding URL
// 		return imageUrl;
// 	  } else {
// 		console.error('Error fetching image:', response.status, response.statusText);
// 		return null;
// 	  }
// 	} catch (error) {
// 	  console.error('Fetch error:', error);
// 	  return null;
// 	}
//   };

// export const getpdfgeneratesrc = async (pageNo, size = 0, pdf = {}) => {

// 	if (!pdf.pdfID) return false;
// 	try {
// 	  const headers = {};
// 	  if (pdf.etag) {
// 		headers['If-None-Match'] = pdf.etag;
// 	  }

// 	  const response = await Service.get(`${HOST}/view-image.dll?id=${pdf.pdfID}&count=${pageNo}&imagesize=${size}`, {
// 		headers,
// 	  });

// 	  if (response.status === 202) {
// 		const etag = response.headers['etag'];
// 		if (etag && etag === pdf.etag) {
// 		  // Content has not changed, return cached data
// 		  return pdf.cachedData;
// 		} else {
// 		  const data = response.data.data;

// 		  // Store the new ETag in local storage
// 		  localStorage.setItem('pdfETag', etag);

// 		  return data;
// 		}
// 	  } else if (response.status === 304) {
// 		// Not Modified: Return cached data
// 		return pdf.cachedData;
// 	  }

// 	  return null; // Handle other response cases here
// 	} catch (error) {
// 	  console.error('Try catch error:', error);
// 	  return null;
// 	}
//   };

// Get the stored ETag from local storage
export const getStoredETag = () => {
  return localStorage.getItem("pdfETag");
};

export const getS3ImageSrc = (company, pdfid, i, name) => {
  if (company && name) {
    var url = `${ASSETS_URI}/${company}/${pdfid}/${i}/${name}`;
    return url;
  } else {
    return "";
  }
};

export const shareToNetwork = (type, shareUrl) => {
  const url = encodeURIComponent(shareUrl ? shareUrl : window.location.href);

  switch (type) {
    case "fb":
      window.open(`https://www.facebook.com/sharer/sharer.php?u=${url}`);
      break;

    case "tw":
      window.open(`https://twitter.com/share?url=${url}`);
      break;

    case "ln":
      window.open(`https://www.linkedin.com/shareArticle?mini=true&url=${url}`);
      break;

    case "wa":
      window.open(`https://api.whatsapp.com/send?phone=&text=${url}`);
      break;
    case "eb":
      window.open(`https://api.whatsapp.com/send?phone=&text=${url}`);
      break;
    default:
      break;
  }
};

export const copyContent = (content) => {
  if (navigator.clipboard !== "undefined") {
    navigator.clipboard.writeText(content);
  } else if (window.clipboardData) {
    window.clipboardData.setData("Text", content);
  }

  return true;
};

export const formatProductData = (product) => {
  if (!product) return null;
  return {
    type: "product",
    sku_code: product.sku_code,
    product_name: product.product_name,
    page_no: product.page_no,
    collectionID: product?.collectionID,
    collectionName: product?.collectionName,
    collectionDescription: product?.collectionDescription,
    node: {
      h: product.height,
      w: product.width,
      x: product.x_cord,
      y: product.y_cord,
    },
    meta: {
      size: product.btn_size,
      theme: product.btn_theme,
      type: product.btn_type,
      id: product.div_id,
      transparent: product.transparent,
    },
    uid: uuidv4(),
  };
};

export const formatResponse = async (api) => {
  try {
    const {
      data: { data, error },
    } = await api;
    if (error) {
      return {
        error: true,
        data: null,
      };
    }
    return {
      data: data,
      error: false,
    };
  } catch (error) {
    return {
      data: null,
      error: true,
    };
  }
};

export { ecollatHelper, productHelper };
