import React from 'react'

const PrivacyPolicyCom = ({ privacyPolicyData }) => {
    return (
        <div className="container">
            <div className="row privacyComMargintop">
                <div className="privacyHeading">
                    <h2>Privacy Policy</h2>
                </div>
                <div dangerouslySetInnerHTML={{ __html: privacyPolicyData }} />
            </div>
        </div>
    )
}

export default PrivacyPolicyCom