import React, { Component } from "react";
import { Redirect } from "react-router-dom";

const HowItWorks = () => {
  const { location } = this.props;

  // Uncomment the following lines if you want to redirect if no URL is provided
  // if (!location.state || !location.state.url) {
  //   return <Redirect to="/" />;
  // }

  return (
    <video width="100%" height="100%" controls autoPlay>
      {/* Uncomment the following line and replace 'your_video_url.mp4' with the actual video URL */}
      {/* <source src={location.state.url} type="video/mp4" /> */}
    </video>
  );
};

export default HowItWorks;
