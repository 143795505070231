import React, { PureComponent, useEffect, useRef, useState } from "react";
import { svgIcon, shareToNetwork, copyContent, SVG } from "../../Functions";
import { toast } from "react-toastify";
import { HOST } from "../../config";
import Service from "../../Action/service";
import {
  submitDownloadForm,
  submitCartDownloadForm,
  getDownloadTimeApi,
} from "../../Action";
import classNames from "classnames";
import SpinnerLoader from "../../Common/SpinnerLoader";
import { checkCartSession } from "../../Functions/product-handler";
import axios from "axios";
import { generatePDFFromImages } from "../../Functions/images";
import Carousel from "../../Functions/carousel";
const initialState = {
  download_PDF_FormData: {},
  downloadSubmitDisabled: false,
  cartDownload_PDF_FormData: {
  },
  cartDownloadSubmitDisabled: false,
  errors: {},
  addproductdetail: {
    data: [],
    error: false,
    loading: false,
  },
  showFullDescription: false,
  productdetail: {
    tabbar: 1,
    mainimage: "",
  },
  currentIndex: 0,
  previousdata: [],
  DownloadPDFloader: false,
};


const Popup = (props) => {
  const [state, setState] = useState(initialState);
  const [cartDownload_PDF_FormData, setcartDownload_PDF_FormData] = useState({
    name: "",
    email: "",
    phone: "",
    queryDetails: "",
    enquiryType: 1,
    address: "",
    city: "",
    state: "",
    pincode: ""
  })
  const [cartDownloadSubmitDisabled, setcartDownloadSubmitDisabled] = useState(false)
  const textRef = useRef(null);
  const prevConfig = useRef(props.config);
  const _copy = (e) => {
    const text = textRef.current;
    if (!text) return;
    text.select();
    document.execCommand("copy");

    if (window.getSelection) window.getSelection().removeAllRanges();
    else if (document.selection) document.selection.empty();

    let child = e.currentTarget.childNodes;
    child[0].innerText = "COPIED";
  };
  const _copyembed = (e) => {
    const text = textRef.current;
    if (!text) return;
    text.select();
    document.execCommand("copy");

    if (window.getSelection) window.getSelection().removeAllRanges();
    else if (document.selection) document.selection.empty();

    let child = e.currentTarget.childNodes;
    child[0].innerText = "COPIED";
  };
  const _renderSharePopup = () => {
    return (
      <div className="popup-body">
        <div className="popup-title">
          <h2>Share</h2>
        </div>
        <div className="popup-content">
          <div className="row">
            <div className="column has-input has-btn">
              <input
                type="text"
                value={window.location.href}
                readOnly
                ref={textRef}
              />
              <button className="input-btn" onClick={_copy}>
                <span>COPY</span>{" "}
                <span className="btn-icon">{svgIcon("ico-copy")}</span>
              </button>
            </div>
          </div>
          <div className="row">
            <div className="column social-share-ico">
              <span
                className="nav-btn inverse"
                onClick={() => shareToNetwork("fb")}
              >
                {svgIcon("ico-facebook")}
              </span>
              <span
                className="nav-btn inverse"
                onClick={() => shareToNetwork("tw")}
              >
                {svgIcon("ico-twitter")}
              </span>
              <span
                className="nav-btn inverse"
                onClick={() => shareToNetwork("ln")}
              >
                {svgIcon("ico-linkedin")}
              </span>
              <span
                className="nav-btn inverse"
                onClick={() => shareToNetwork("wa")}
              >
                {svgIcon("ico-whatsapp")}
              </span>
              {/* <span
                className="nav-btn inverse"
                onClick={() => _copyembed("eb")}
              >
                {svgIcon("ico-whatsapp")}
              </span> */}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const _shareCustomPdf = () => {
    const {
      config: { data },
    } = props;
    const handleDownloadPdfCustom = () => { };
    return (
      <div className="popup-body">
        <div className="popup-title">
          <h2>Shareable Link</h2>
        </div>
        <div className="popup-content">
          <div className="row">
            <div className="column has-input has-btn">
              <input type="text" value={data.link} readOnly ref={textRef} />
              <button id="copyBtnTrack" className="input-btn" onClick={_copy}>
                <span id="copyBtnTrack">COPY</span>{" "}
                <span id="copyBtnTrack" className="btn-icon">
                  {svgIcon("ico-copy")}
                </span>
              </button>
            </div>
          </div>
          <div className="row customLinkSpace">
            <div className="column social-share-ico">
              <span
                className="nav-btn inverse"
                onClick={() => shareToNetwork("fb", data.link)}
              >
                {svgIcon("ico-facebook")}
              </span>
              <span
                className="nav-btn inverse"
                onClick={() => shareToNetwork("tw", data.link)}
              >
                {svgIcon("ico-twitter")}
              </span>
              <span
                className="nav-btn inverse"
                onClick={() => shareToNetwork("ln", data.link)}
              >
                {svgIcon("ico-linkedin")}
              </span>
              <span
                className="nav-btn inverse"
                onClick={() => shareToNetwork("wa", data.link)}
              >
                {svgIcon("ico-whatsapp")}
              </span>
            </div>
            {/* {
                            state.downloadSubmitDisabled ? (
                                <div className='spinnerBg'>
                                    <div class="bt-spinner"></div>
                                </div>
                            )
                                :
                                (
                                    <button
                                        className={'btnCustomLink'}
                                        type="submit"
                                        onClick={() => handleDownloadPdfCustom(data)}
                                    >
                                        Download
                                    </button>
                                )
                        } */}
          </div>
        </div>
      </div>
    );
  };
  const downloadFile = async (url, name) => {
    var xhr = new XMLHttpRequest();
    xhr.open("GET", `${url}`, true);
    xhr.responseType = "arraybuffer";
    xhr.onload = function (e) {
      if (xhr.status == 200) {
        var blob = new Blob([xhr.response], { type: "application/pdf" });
        var link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = name;
        link.click();

        toast.success(name + " downloaded ", {
          className: "toast-style",
        });
      }
    };
    xhr.send();
    // let response = await Service.get(url, {
    //     headers: {
    //         responseType: 'arraybuffer'
    //     }
    // });
    // const x = window.URL.createObjectURL(new Blob([response.data], { type: 'text/pdf' }));
    // const link = document.createElement('a');
    // link.href = x;
    // link.setAttribute('download', name); //or any other extension
    // document.body.appendChild(link);
    // link.click();
  };

  const _renderContactInfo = () => {
    const {
      config: { data, socialMediaLinks },
    } = props;
    return (
      <div className="popup-body">
        {data !== "<p></p>\n" && (
          <div className="popup-title marginBottom10">
            <h2>Contact Us</h2>
          </div>
        )}
        <div className="popup-content">
          {data !== "<p></p>\n" && (
            <div className="has-input has-btn forOverFlowContactModalcontact marginBottom10">
              <div
                className="contactModalMaxHeight"
                dangerouslySetInnerHTML={{ __html: data }}
              />
            </div>
          )}
          {socialMediaLinks?.fbPath !== "" ||
            socialMediaLinks?.instaPath !== "" ||
            socialMediaLinks?.youtube !== "" ||
            socialMediaLinks?.twitter !== "" ||
            socialMediaLinks?.linkedin !== "" ||
            socialMediaLinks?.whatsapp !== "" ||
            socialMediaLinks?.website !== "" ? (
            <>
              <h4 className="socialMediaLinks">Our Social Media Links:</h4>
              <div className="social-share-ico">
                {socialMediaLinks?.fbPath && (
                  <span
                    className="nav-btn inverse"
                    onClick={() => window.open(socialMediaLinks?.fbPath)}
                  >
                    {svgIcon("ico-facebook")}
                  </span>
                )}
                {socialMediaLinks?.instaPath && (
                  <span
                    className="nav-btn inverse"
                    onClick={() => window.open(socialMediaLinks?.instaPath)}
                  >
                    {svgIcon("ico-insta")}
                  </span>
                )}
                {socialMediaLinks?.youtube && (
                  <span
                    className="nav-btn inverse"
                    onClick={() => window.open(socialMediaLinks?.youtube)}
                  >
                    {svgIcon("ico-youtube")}
                  </span>
                )}
                {socialMediaLinks?.twitter && (
                  <span
                    className="nav-btn inverse"
                    onClick={() => window.open(socialMediaLinks?.twitter)}
                  >
                    {svgIcon("ico-twitter")}
                  </span>
                )}
                {socialMediaLinks?.linkedin && (
                  <span
                    className="nav-btn inverse"
                    onClick={() => window.open(socialMediaLinks?.linkedin)}
                  >
                    {svgIcon("ico-linkedin")}
                  </span>
                )}
                {socialMediaLinks?.whatsapp && (
                  <span
                    className="nav-btn inverse"
                    onClick={() =>
                      window.open(`tel:${socialMediaLinks?.whatsapp}`)
                    }
                  >
                    {svgIcon("ico-whatsapp")}
                  </span>
                )}
                {socialMediaLinks?.website && (
                  <span
                    className="nav-btn inverse"
                    onClick={() => window.open(socialMediaLinks?.website)}
                  >
                    {svgIcon("ico-website")}
                  </span>
                )}
              </div>
            </>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  };

  const _renderDownloadable = () => {
    const {
      config: { data },
    } = props;
    let count = 0;

    return (
      <div className="popup-body">
        <div className="popup-title">
          <h2>Product Downloadables</h2>
        </div>
        <div className="popup-content">
          {!data || data.length <= 0 ? (
            <h2>No downloadables found!</h2>
          ) : (
            // <ul className="product-downloadables">
            //     {data.map((link, i) => {
            //         const linkSplit = link.split("/")
            //         return (
            //             <li key={i}>
            //                 <a h{`${HOST}/${link}`}
            //                     download={linkSplit[linkSplit.length - 1]}
            //                     target="_blank"
            //                     rel="noopener noreferrer"
            //                 >
            //                 <div style={{ cursor: "pointer" }} onClick={(e) => downloadFile(`${link}`, linkSplit[linkSplit.length - 1])}>
            //                     <span className="count">{i + 1}.</span>
            //                     <span children={linkSplit[linkSplit.length - 1]} />
            //                     <span className="ico" children={svgIcon('ico-download')} />
            //                 </div>
            //                 </a>
            //             </li>

            //         )
            //     }
            //     )}
            // </ul>

            <div className="product-downloadables">
              <table>
                <thead>
                  <tr>
                    <th style={{ width: "40px", textAlign: "center" }}>
                      {" "}
                      S.no{" "}
                    </th>
                    <th style={{ width: "140px" }}> Sku Code </th>
                    <th style={{ width: "310px" }}> File Name </th>
                    <th style={{ width: "100px", textAlign: "center" }}>
                      {" "}
                      Download{" "}
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {data.map(
                    (item) =>
                      item.things_download != null &&
                      item.things_download.map((link, i) => {
                        const linkSplit = link.split("/");
                        return (
                          <tr>
                            <td className="text-center"> {++count} </td>
                            <td> {item.sku_code} </td>
                            <td> {linkSplit[linkSplit.length - 1]} </td>
                            <td className="text-center">
                              <span
                                style={{ cursor: "pointer" }}
                                className="ico"
                                children={svgIcon("ico-download")}
                                onClick={(e) =>
                                  downloadFile(
                                    `${link}`,
                                    linkSplit[linkSplit.length - 1]
                                  )
                                }
                              />
                            </td>
                          </tr>
                        );
                      })
                  )}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
    );
  };

  const _renderIframe = () => {
    const {
      config: { data },
    } = props;
    let srcUri = data;
    if (data.search("youtube.com") !== -1 && data.search("watch?v=") === -1) {
      const videoId = data.split("=")[1];
      srcUri = data.replace("watch?v=", "embed/");
      srcUri =
        srcUri +
        `?loop=1&playlist=${videoId}&controls=0&autoplay=0&frameborder=0&showinfo=0`;
    }

    return (
      <div className="popup-body">
        <iframe
          src={srcUri}
          allowtransparency="true"
          allow="accelerometer; autoplay; encrypted-media; gyroscope"
          frameBorder="0"
          allowFullScreen
          title="ss"
        />
      </div>
    );
  };

  const _renderHowItWorksVideo = () => {
    const {
      config: { data },
    } = props;

    return (
      <video width="100%" height="100%" controls autoPlay>
        <source src={data} type="video/mp4" />
      </video>
    );
  };

  const _renderPDFDownloadFrom = () => {
    return (
      <div className="popup-body">
        <div className="popup-title">
          <h2>Enter Details</h2>
        </div>
        <div className="popup-content">
          <div className="row">
            <div className="column">
              <form
                className="download-pdf-form"
                onSubmit={(e) => handleDownloadForm(e)}
              >
                <div className="form-group">
                  <label> Name :</label>
                  <input
                    type="text"
                    placeholder="Enter Name"
                    name="name"
                    onChange={onChangeDownloadFormHandler}
                    value={state.download_PDF_FormData.name}
                  />
                </div>
                {state.errors["name"] && (
                  <p className="textDanger1">{state.errors["name"]}</p>
                )}
                <div className="form-group">
                  <label> Email :</label>
                  <input
                    type="email"
                    placeholder="Enter Email"
                    name="email"
                    onChange={onChangeDownloadFormHandler}
                    value={state.download_PDF_FormData.email}
                  />
                </div>
                {state.errors["email"] && (
                  <p className="textDanger1">{state.errors["email"]}</p>
                )}

                <div className="form-group">
                  <label> Contact No :</label>
                  <input
                    type="phone"
                    placeholder="Enter Number"
                    name="phone"
                    onChange={onChangeDownloadFormHandler}
                    value={state.download_PDF_FormData.phone}
                  />
                </div>
                {state.errors["contactNo"] && (
                  <p className="textDanger1">{state.errors["contactNo"]}</p>
                )}
                {/* <button
                                    className={classNames({
                                        'submit': true,
                                        'disabled': state.downloadSubmitDisabled
                                    })}
                                    type="submit"
                                    disabled={state.downloadSubmitDisabled}
                                >
                                    Submit
                                </button> */}
                {state.downloadSubmitDisabled ? (
                  <div className="spinnerBg">
                    <div className="bt-spinner"></div>
                  </div>
                ) : (
                  <button
                    className={`${classNames({
                      // 'submit': true,
                      // 'disabled': state.cartDownloadSubmitDisabled
                    })} btn`}
                    type="submit"
                  >
                    Submit
                  </button>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const _renderCartDownloadFrom = () => {
    return (
      <div className="popup-body">
        <div className="popup-title">
          <h2>Enter Details</h2>
        </div>
        <div className="popup-content">
          <div className="row">
            <div className="column">
              <form
                className="download-pdf-form cart-download"
              // onSubmit={(e) => handleCartDownloadForm(e)}
              >
                <div className="form-group">
                  <label>
                    Name <span className="mandatory-field"> * </span> :
                  </label>
                  <input
                    type="text"
                    placeholder="Enter Name"
                    name="name"
                    onChange={onChangeCartDownloadFormHandler}
                    value={cartDownload_PDF_FormData?.name || ""}
                  />
                </div>
                {state?.errors["name"] && (
                  <p className="textDanger">{state?.errors["name"]}</p>
                )}

                <div className="form-group">
                  <label>
                    Email <span className="mandatory-field"> * </span> :
                  </label>
                  <input
                    type="email"
                    placeholder="Enter Email"
                    name="email"
                    onChange={onChangeCartDownloadFormHandler}
                    value={cartDownload_PDF_FormData.email || ""}
                  />
                </div>
                {state.errors["email"] && (
                  <p className="textDanger">{state.errors["email"]}</p>
                )}

                <div className="form-group">
                  <label>
                    Contact No <span className="mandatory-field"> * </span> :
                  </label>
                  <input
                    type="phone"
                    placeholder="Enter Number"
                    name="phone"
                    onChange={onChangeCartDownloadFormHandler}
                    value={cartDownload_PDF_FormData.phone || ""}
                  />
                </div>
                {state.errors["phone"] && (
                  <p className="textDanger">{state.errors["phone"]}</p>
                )}
                <div className="form-group">
                  <label> Query Details :</label>
                  <textarea
                    onChange={onChangeCartDownloadFormHandler}
                    value={cartDownload_PDF_FormData.queryDetails || ""}
                    name="queryDetails"
                    placeholder="Enter Your Queries"
                  />
                </div>

                <div className="form-group">
                  <label>Enquiry Type :</label>
                  <select
                    onChange={onChangeCartDownloadFormHandler}
                    name="enquiryType"
                  >
                    <option disabled> Select Option </option>
                    <option value="1"> New product requirement </option>
                    <option value="2"> Service enquiry </option>
                  </select>
                </div>

                <div className="form-group">
                  <label> Address :</label>
                  <textarea
                    onChange={onChangeCartDownloadFormHandler}
                    value={cartDownload_PDF_FormData.address}
                    name="address"
                    placeholder="Enter Address"
                  />
                </div>

                <div className="form-group">
                  <label>City :</label>
                  <input
                    type="text"
                    placeholder="Enter City"
                    name="city"
                    onChange={onChangeCartDownloadFormHandler}
                    value={cartDownload_PDF_FormData.city}
                  />
                </div>

                <div className="form-group">
                  <label>State :</label>
                  <input
                    type="text"
                    placeholder="Enter State"
                    name="state"
                    onChange={onChangeCartDownloadFormHandler}
                    value={cartDownload_PDF_FormData.state}
                  />
                </div>

                <div className="form-group">
                  <label>
                    Pincode <span className="mandatory-field"> * </span> :
                  </label>
                  <input
                    type="phone"
                    placeholder="Enter Pincode"
                    name="pincode"
                    onChange={onChangeCartDownloadFormHandler}
                    value={cartDownload_PDF_FormData.pincode}
                  />
                </div>
                {state.errors["pincode"] && (
                  <p className="textDanger">{state.errors["pincode"]}</p>
                )}
                {cartDownloadSubmitDisabled ? (
                  <div className="spinnerBg">
                    <div className="bt-spinner"></div>
                  </div>
                ) : (
                  <button
                    className={`${classNames({
                      // 'submit': true,
                      // 'disabled': state.cartDownloadSubmitDisabled
                    })} btn`}
                    type="button"
                    onClick={(e) => handleCartDownloadForm(e)}
                  >
                    Submit
                  </button>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const onChangeDownloadFormHandler = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setState({
      ...state,
      download_PDF_FormData: {
        ...state.download_PDF_FormData,
        [name]: value,
      },
    });

    const nameField = state.download_PDF_FormData.name != "" ? true : false;
    const emailField = state.download_PDF_FormData.email != "" ? true : false;
    const phoneField = state.download_PDF_FormData.contact != "" ? true : false;

    if (nameField && emailField && phoneField) {
      setState({
        ...state,
        downloadSubmitDisabled: false,
      });
    }
  };

  const onChangeCartDownloadFormHandler = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    // setState({
    //   ...state,
    //   cartDownload_PDF_FormData: {
    //     ...cartDownload_PDF_FormData,
    //     [name]: value,
    //   },
    // });
    setcartDownload_PDF_FormData({
      ...cartDownload_PDF_FormData,
      [name]: value,
    })
    const nameField = cartDownload_PDF_FormData.name != "" ? true : false;
    const emailField =
      cartDownload_PDF_FormData.email != "" ? true : false;
    const phoneField =
      cartDownload_PDF_FormData.contact != "" ? true : false;

    if (nameField && emailField && phoneField) {
      setState({
        ...state,
        cartDownloadSubmitDisabled: false,
      });

    }
  };
  function handleDownloadValidation(fields) {
    let errors = {};
    let formIsValid = true;

    if (!fields["name"]) {
      formIsValid = false;
      errors["name"] = "Cannot be empty";
    }

    // else if (typeof fields["name"] !== "undefined") {
    //     if (!fields["name"].match(/^[A-Za-z]+$/)) {
    //         formIsValid = false;
    //         errors["name"] = "Only letters are allowed";
    //     }
    // }
    else if (!isNaN(fields["name"])) {
      formIsValid = false;
      errors["name"] = "Only letters are allowed";
    }

    if (!fields["email"]) {
      formIsValid = false;
      errors["email"] = "Cannot be empty";
    } else if (typeof fields["email"] !== "undefined") {
      let lastAtPos = fields["email"].lastIndexOf("@");
      let lastDotPos = fields["email"].lastIndexOf(".");

      if (
        !(
          lastAtPos < lastDotPos &&
          lastAtPos > 0 &&
          fields["email"].indexOf("@@") == -1 &&
          lastDotPos > 2 &&
          fields["email"].length - lastDotPos > 2
        )
      ) {
        formIsValid = false;
        errors["email"] = "Email is not valid";
      }
    }
    if (!fields["contactNo"]) {
      formIsValid = false;
      errors["contactNo"] = "Cannot be empty";
    } else if (isNaN(fields?.contactNo)) {
      formIsValid = false;
      errors["contactNo"] = "Only Numbers are allowed";
    } else if (!fields["contactNo"].match(/^\d{10}$/)) {
      formIsValid = false;
      errors["contactNo"] = "Mobile no must be ten digit";
    }
    setState({
      ...state,
      errors: errors,
    });
    return formIsValid;
  }

  const handleDownloadForm = async (e) => {
    e.preventDefault();
    const {
      config: { data },
    } = props;
    const name = e.target.name.value;
    const email = e.target.email.value;
    const mobile = e.target.phone.value;

    const _formData = {
      name,
      email,
      contactNo: mobile,
      pdfID: data.data.pdfID,
      clientID: data.data.clientID,
    };
    try {
      if (handleDownloadValidation(_formData)) {
        setState({
          ...state,
          downloadSubmitDisabled: true,
        });
        const res = await submitDownloadForm(_formData);

        // let link = document.createElement("a");
        // link.href = data.data.url;
        // link.download = data.data.pdfSlug;
        // link.dispatchEvent(new MouseEvent("click"));
        // toast.success(res.message);
        if (res?.success) {
          await Service.get(data.data.url).then((res) => {
            const url = res.data.data;

            let downloadStartTime; // Variable to store the download start time

            axios
              .get(url, {
                responseType: "blob",
                onDownloadProgress: (progressEvent) => {
                  if (!downloadStartTime) {
                    downloadStartTime = new Date(); // Record the download start time
                  }
                  const { loaded, total } = progressEvent;
                  const percentCompleted = Math.round((loaded / total) * 100);
                  // Update your progress bar or display the progress
                },
              })
              .then((response) => {
                // Rest of your download completion code

                // Calculate the time it took to download
                const downloadEndTime = new Date();
                const downloadTimeInMilliseconds =
                  downloadEndTime - downloadStartTime;

                // Convert the download time to a human-readable format
                const downloadTimeInSeconds = Math.floor(
                  downloadTimeInMilliseconds / 1000
                );
                const downloadTimeMinutes = Math.floor(
                  downloadTimeInSeconds / 60
                );
                const downloadTimeSeconds = downloadTimeInSeconds % 60;
                getDownloadTimeApi({
                  pdfID: data.pdfID,
                  clientID: data.clientID,
                  downTime: downloadTimeInMilliseconds,
                });
                setState({
                  ...state,
                  DownloadPDFloader: false,
                });
                props.close();
                // Create a Blob from the PDF data and create a download link
                const blob = new Blob([response.data], {
                  type: "application/pdf",
                });
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement("a");
                a.style.display = "none";
                a.href = url;
                a.download = data.data.pdfSlug;
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
                setState({
                  ...state,
                  download_PDF_FormData: {},
                  downloadSubmitDisabled: false,
                });
              })
              .catch((error) => {
                console.error("Error downloading PDF:", error);
                setState({
                  ...state,
                  downloadSubmitDisabled: false,
                });
                props.close();
              });

            // let link = document.createElement("a");
            // link.href = res.data.data;
            // link.download = pdfSlug;
            // link.dispatchEvent(new MouseEvent("click"));
          });
        }
        if (!res) {
          setState({
            ...state,
            downloadSubmitDisabled: false,
          });
        }
      }
    } catch (error) {
      setState({
        ...state,
        downloadSubmitDisabled: false,
      });
      // toast.success(error.response.message)
    }
  };

  function handleCartDownloadValidation(fields) {
    let errors = {};
    let formIsValid = true;

    //Name
    if (!fields["name"]) {
      formIsValid = false;
      errors["name"] = "Cannot be empty";
    }

    // else if (typeof fields["name"] !== "undefined") {
    //     if (!fields["name"].match(/^[a-zA-Z]+$/)) {
    //         formIsValid = false;
    //         errors["name"] = "Only letters are allowed";
    //     }
    // }
    else if (!isNaN(fields["name"])) {
      formIsValid = false;
      errors["name"] = "Only letters are allowed";
    }

    //Email
    if (!fields["email"]) {
      formIsValid = false;
      errors["email"] = "Cannot be empty";
    } else if (typeof fields["email"] !== "undefined") {
      let lastAtPos = fields["email"].lastIndexOf("@");
      let lastDotPos = fields["email"].lastIndexOf(".");

      if (
        !(
          lastAtPos < lastDotPos &&
          lastAtPos > 0 &&
          fields["email"].indexOf("@@") == -1 &&
          lastDotPos > 2 &&
          fields["email"].length - lastDotPos > 2
        )
      ) {
        formIsValid = false;
        errors["email"] = "Email is not valid";
      }
    }
    if (!fields["phone"]) {
      formIsValid = false;
      errors["phone"] = "Cannot be empty";
    } else if (isNaN(fields?.phone)) {
      formIsValid = false;
      errors["phone"] = "Only Numbers are allowed";
    } else if (!fields["phone"].match(/^\d{10}$/)) {
      formIsValid = false;
      errors["phone"] = "Mobile no must be ten digit";
    }
    if (!fields["pincode"]) {
      formIsValid = false;
      errors["pincode"] = "Cannot be empty";
    } else if (isNaN(fields?.pincode)) {
      formIsValid = false;
      errors["pincode"] = "Only Numbers are allowed";
    }
    setState({ ...state, errors: errors });
    return formIsValid;
  }
  const downloadPDF = async (url, pdfSlug, pages) => {
    if (pages) {
      // const data=await fetchImage(props.pdf)
      /**  to make the pdf from frontend */
      setState({ ...state, DownloadPDFloader: true });
      await generatePDFFromImages(props.pdf.pdfID, pages).then(() => {
        setState({ ...state, DownloadPDFloader: false });
      });
    } else {
      const { showForm } = props.pdf;
      const data = {
        url,
        pdfSlug,
        pdfID: props.pdf.pdfID,
        clientID: props.pdf.clientID,
      };

      const popupConfig = {
        isActive: true,
        data,
        type: "downloadPDF",
      };

      if (showForm) {
        Popup("downloadPDF", popupConfig);
      } else {
        setState({ ...state, DownloadPDFloader: true });
        await Service.get(url).then((res) => {
          const url = res.data.data;

          let downloadStartTime; // Variable to store the download start time

          axios
            .get(url, {
              responseType: "blob",
              onDownloadProgress: (progressEvent) => {
                if (!downloadStartTime) {
                  downloadStartTime = new Date(); // Record the download start time
                }
                const { loaded, total } = progressEvent;
                const percentCompleted = Math.round((loaded / total) * 100);
                // Update your progress bar or display the progress
              },
            })
            .then((response) => {
              // Rest of your download completion code

              // Calculate the time it took to download
              const downloadEndTime = new Date();
              const downloadTimeInMilliseconds =
                downloadEndTime - downloadStartTime;

              // Convert the download time to a human-readable format
              const downloadTimeInSeconds = Math.floor(
                downloadTimeInMilliseconds / 1000
              );
              const downloadTimeMinutes = Math.floor(
                downloadTimeInSeconds / 60
              );
              const downloadTimeSeconds = downloadTimeInSeconds % 60;
              getDownloadTimeApi({
                pdfID: data.pdfID,
                clientID: data.clientID,
                downTime: downloadTimeInMilliseconds,
              });
              setState({ ...state, DownloadPDFloader: false });

              // Create a Blob from the PDF data and create a download link
              const blob = new Blob([response.data], {
                type: "application/pdf",
              });
              const url = window.URL.createObjectURL(blob);
              const a = document.createElement("a");
              a.style.display = "none";
              a.href = url;
              a.download = pdfSlug;
              document.body.appendChild(a);
              a.click();
              window.URL.revokeObjectURL(url);
            })
            .catch((error) => {
              console.error("Error downloading PDF:", error);
              setState({ ...state, DownloadPDFloader: false });
            });

          // let link = document.createElement("a");
          // link.href = res.data.data;
          // link.download = pdfSlug;
          // link.dispatchEvent(new MouseEvent("click"));
        });
        // setState({ DownloadPDFloader: false });
      }
    }
  };
  const handleCartDownloadForm = async (e) => {
    e.preventDefault();
    setcartDownloadSubmitDisabled(true)
    const _enquiryType = ["New product requirement", "Service enquiry"];
    const {
      config: { data },
    } = props;
    const name = cartDownload_PDF_FormData.name;
    const email = cartDownload_PDF_FormData.email;
    const phone = cartDownload_PDF_FormData.phone;
    const address = cartDownload_PDF_FormData.address;
    const city = cartDownload_PDF_FormData.city;
    const state = cartDownload_PDF_FormData.state;
    const pincode = cartDownload_PDF_FormData.pincode;
    const enquiryType = _enquiryType[parseInt(cartDownload_PDF_FormData.enquiryType) - 1];
    const queryDetails = cartDownload_PDF_FormData.queryDetails;
    let _formData = {
      name,
      email,
      phone,
      city,
      state,
      pincode,
      enquiryType,
      queryDetails,
      clientID: data.data.clientID,
    };

    if (address != "") {
      _formData.address = address;
    }

    try {
      if (handleCartDownloadValidation(_formData)) {
        // setState({
        //   ...state,
        //   cartDownloadSubmitDisabled: true,
        // });
        const res = await submitCartDownloadForm(_formData);

        if (res.success) {
          let a = document.createElement("a");
          a.href = window.URL.createObjectURL(data.data.url);
          // Give filename you wish to download
          a.download = "cart.pdf";
          a.style.display = "none";
          document.body.appendChild(a);
          a.click();
          toast.success(res.message);
          data.closeCart();
          props.close();
          // setState({
          //   ...state,
          //   cartDownloadSubmitDisabled: false,
          //   cartDownload_PDF_FormData: {},
          // });
          setcartDownload_PDF_FormData({})
          setcartDownloadSubmitDisabled(false)
        }
      }
      setcartDownloadSubmitDisabled(false)

    } catch (error) {
      setState({
        ...state,
        cartDownloadSubmitDisabled: false,
      });
      setcartDownload_PDF_FormData({})
      setcartDownloadSubmitDisabled(false)

      alert(error);

      // toast.success(error.response.message)
    }
  };

  const fetchproductdetails = async (pdf, data) => {
    setState({
      ...state,
      addproductdetail: {
        data: [],
        error: false,
        loading: true,
      },
    });
    try {
      const result = await Service.post(`/public/getproductsmeta`, {
        clientID: pdf.clientID,
        productSku: data.skucode,
      });
      if (result?.data?.success) {
        setState({
          ...state,
          addproductdetail: {
            error: false,
            data: result.data.data,
            loading: false,
          },
          productdetail: {
            ...state.productdetail,
            mainimage: result.data.data.productImage,
          },
          previousdata: [result.data.data],
        });
      } else {
        setState({
          ...state,
          addproductdetail: {
            data: [],
            error: true,
            loading: false,
          },
        });
      }
    } catch (error) {
      setState({
        ...state,
        addproductdetail: {
          data: [],
          error: true,
          loading: false,
        },
      });
    }
  };
  useEffect(() => {
    if (props.config !== prevConfig.current && props.config !== undefined) {
      const { pdf, data } = props.config;
      fetchproductdetails(pdf, data);
    }

    // Update the previous config after the effect runs
    prevConfig.current = props.config;
  }, [props.config]);

  const addToCart = async (sku_code, name, pdfID, pQunatity) => {
    const sessionID = checkCartSession();
    try {
      const res = await Service.post(`public/addtocart`, {
        sku_code: [sku_code],
        pdf_id: pdfID,
        cart_id: sessionID,
        com_slug: window.__E_TT_Client.companySlug,
        pQunatity: pQunatity ? pQunatity : 1,
      });
      const error = await res.data.success;
      if (!error) {
        toast.success("something went wrong!", {
          className: "toast-style",
        });
      } else {
        toast.success(name + " successfully added!", {
          className: "toast-style",
        });

        props.fetchCartCounterData(); /**  Comment for now */
      }
    } catch (error) {
      toast.success("something went wrong!", {
        className: "toast-style",
      });
    }
  };

  // For related Product
  const toggleDescription = () => {
    setState((prevState) => ({
      ...state,
      showFullDescription: !prevState.showFullDescription,
    }));
  };
  const change = (val) => {
    setState({
      ...state,
      productdetail: {
        ...state.productdetail,
        tabbar: val,
      },
    });
  };
  const nextProduct = () => {
    const { loading, error, data } = state.addproductdetail;
    setState((prevState) => ({
      ...state,
      currentIndex: (prevState.currentIndex + 5) % data.childProducts.length,
    }));
  };

  const prevProduct = () => {
    const { loading, error, data } = state.addproductdetail;
    setState((prevState) => ({
      ...state,
      currentIndex:
        prevState.currentIndex === 0
          ? data.childProducts.length - 5
          : prevState.currentIndex - 5,
    }));
  };

  const fetchRelatedproduct = async (pdf, data) => {
    setState({
      ...state,
      addproductdetail: {
        data: [],
        error: false,
        loading: true,
      },
    });

    try {
      const result = await Service.post(`/public/getproductsmeta`, {
        clientID: pdf.clientID,
        productSku: data.productSKu,
      });

      const success = result?.data?.success;

      setState({
        ...state,
        addproductdetail: {
          data: success ? result.data.data : [],
          error: !success,
          loading: false,
        },
        productdetail: {
          ...state.productdetail,
          mainimage: success ? result.data.data.productImage : [],
        },
        previousdata: [...state.previousdata, success ? result.data.data : []],
      });
    } catch (error) {
      setState({
        ...state,
        addproductdetail: {
          data: [],
          error: true,
          loading: false,
        },
      });
    }
  };

  const undodata = (data) => {
    fetchRelatedproduct(props.config.pdf, data);
  };

  const redodata = () => {
    setState({
      ...state,
      addproductdetail: {
        data: [],
        error: false,
        loading: true,
      },
    });
    const alldata = state.previousdata;
    const remainingdata = alldata?.slice(0, alldata.length - 1);
    const newdata = remainingdata[remainingdata.length - 1];

    setState({
      ...state,
      addproductdetail: {
        data: newdata,
        error: false,
        loading: false,
      },
      productdetail: {
        ...state.productdetail,
        mainimage: newdata.productImage,
      },
      previousdata: remainingdata,
    });
  };

  const _renderProductDetail = () => {
    const { addproductdetail, previousdata } = state;
    const { loading, error, data } = addproductdetail;
    const { currentIndex } = state;
    const products = data?.childProducts || [];
    const isMobile = localStorage.getItem("isMobile") === 'true' ? true : false;
    const visibleProducts = isMobile
      ? products
      : products?.slice(currentIndex, currentIndex + 6);
    const hidePrevArrow = isMobile ? true : currentIndex === 0;
    const hideNextArrow = isMobile ? true : visibleProducts.length < 5;

    if (loading) {
      return (
        <>
          <div className="mainproductdiv">
            <div className="headdiv">
              <span className="close-popup-PD" onClick={props.close}>
                {svgIcon("ico-close")}
              </span>
            </div>
            <div>
              <SpinnerLoader />
            </div>
          </div>
        </>
      );
    }
    if (error || data == null) {
      return (
        <>
          <div className="mainproductdiv">
            <div className="headdiv">
              <span className="close-popup-PD" onClick={props.close}>
                {svgIcon("ico-close")}
              </span>
            </div>
            <h1>error</h1>
          </div>
        </>
      );
    }
    return (
      <div className="mainproductdiv">
        <div className="headdiv">
          <span className="close-popup-PD" onClick={props.close}>
            {svgIcon("ico-close")}
          </span>
          {previousdata.length > 1 && (
            <span className="close-popup-PD" onClick={redodata}>
              {SVG.backicon}
            </span>
          )}
        </div>
        <div className="Centerdiv">
          <div className="moreimagedivmain">
            <div className="moreimagediv" style={{ display: "none" }}>
              {products.map((item) => {
                return (
                  <div className="Moreimagediv">
                    <img
                      src={item?.productThumbImage}
                      alt={item.productSKu}
                      height="60px"
                      width="60px"
                      onError={(e) => (e.target.src = "")}
                      onClick={() => {
                        setState({
                          ...state,
                          productdetail: {
                            ...state.productdetail,
                            mainimage: item?.productThumbImage,
                          },
                        });
                      }}
                    />
                  </div>
                );
              })}
            </div>
            <div className="imagediv">
              <img src={state.productdetail.mainimage} alt=".." />
            </div>
          </div>
          <div className="detaildiv">
            <div className="title">
              <h6>{data.productName}</h6>
              <span>{`(${data.productSku})`}</span>
            </div>
            <div>
              <div className="description">
                {state.showFullDescription
                  ? data?.productDescription
                  : data?.productDescription?.slice(0, 155)}
              </div>
              {data?.productDescription?.length > 155 && (
                <p className="redamorebutton" onClick={toggleDescription}>
                  {state.showFullDescription ? "Read Less" : "...Read More"}
                </p>
              )}
            </div>
            <div className="price">
              <h2>Rs. {data.productPrice}</h2>
            </div>
            <div className="button">
              {/* <button className="medium-button Secondary-Button">
                Go to Website
              </button> */}
              <button
                type="button"
                className="medium-button Primary-Button"
                onClick={() =>
                  addToCart(
                    data.productSku,
                    data.productName,
                    props.config.pdf.pdfID
                  )
                }
              >
                Add to cart
              </button>
            </div>
          </div>
        </div>
        <div className="Relateddiv">
          <div className="Tabbar">
            {/* <div
              className={` ${
                state.productdetail.tabbar === 0 ? "active" : "inactive"
              }`}
              onClick={() => change(0)}
            >
              <span>Variants</span>
            </div> */}
            {products.length > 0 && (
              <div
                className={` ${state.productdetail.tabbar === 1 ? "active" : "inactive"
                  }`}
                onClick={() => change(1)}
              >
                <span>Related Products</span>
              </div>
            )}
          </div>
          {state.productdetail.tabbar === 0 && <></>}
          {state.productdetail.tabbar === 1 && products.length > 0 && (
            <div className={`slider-container`}>
              {!hidePrevArrow && (
                <div className="left-arrow" onClick={prevProduct}>
                  {SVG.lefticon}
                </div>
              )}
              <div className={` ${isMobile ? "Mobilerelative" : ""} childrendiv`}>
                {visibleProducts.map((item, index) => (
                  <div
                    key={index}
                    className="productchildren"
                    onClick={() => undodata(item)}
                  >
                    <img src={item?.productThumbImage} alt={item.productSKu} />
                  </div>
                ))}
              </div>
              {!hideNextArrow && (
                <div className="right-arrow" onClick={nextProduct}>
                  {SVG.righticon}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    );
  };

  const _renderEmbedpopup = () => {
    return <></>;
  };
  const _renderCarouselpopup = () => {
    const { data, spot } = props.config

    return (
      <div className="PopupCarousel">
        <Carousel images={[{
          src: "/images/carousal/image3.jpg",
          label: "First Title",
          discription: ""
        },
        {
          src: "/images/carousal/image1.jpg",
          discription: ""
        },
        {
          src: "/images/carousal/image6.jpg",
          discription: ""
        },
        {
          src: "/images/carousal/image7.jpg",
          label: "Last Title",
          discription: ""
        }]}
          spot={spot} isPopup={true} />
      </div>);
  };
  const _render = (type) => {
    switch (type) {
      case "share":
        return _renderSharePopup();

      case "custom-pdf":
        return _shareCustomPdf();

      case "contact":
        return _renderContactInfo();

      case "downloadable":
        return _renderDownloadable();

      case "iframe":
        return _renderIframe();

      case "howItWorks":
        return _renderHowItWorksVideo();

      case "downloadPDF":
        return _renderPDFDownloadFrom();

      case "cartDownload":
        return _renderCartDownloadFrom();
      case "addProductDetails":
        return _renderProductDetail();
      case "Embedpopup":
        return _renderEmbedpopup();
      case "carousel":
        return _renderCarouselpopup();
      default:
        return null;
    }
  };
  if (props.config) {
    // return null;
  } else {
    return null;
  }
  const {
    config: { isActive, type },
  } = props;
  if (!isActive) return null;
  return (
    <div className="popup-wrap">
      <div className={`popup type-${type}`}>
        {type !== "addProductDetails" && (
          <span className="close-popup" onClick={props.close}>
            {svgIcon("ico-close")}
          </span>
        )}
        {_render(type)}
      </div>
    </div>
  );
};
export default Popup;
