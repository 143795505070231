import React, { useContext, useEffect, useRef, useState } from "react";
// import { Swipeable, useSwipeable } from "react-swipeable";
import { ecollatHelper, getImageSrc, isTouchDevice } from "../../Functions";
import Panview from "react-element-pan";
import {
  trackPageGoogleAnalytics,
  trackPageGoogleTagManager,
} from "../../Functions/analytics";
import Modal from "./../../Modal";
import Service from "./../../Action/service";
import { CartContext } from "./context";
import { Helmet } from "react-helmet";
import { useSwipeable } from "react-swipeable";
import { useNavigate, useParams } from "react-router-dom";
import { debounce, unset } from "lodash";
import { PageViewAnalatics } from "../../Functions/analytic";
import ZoomControls from "./ecollat-controllers";
// import { useSwipeable ,Swipeable} from "react-swipeable";
// import HTMLFlipBook from "react-pageflip";
// import HTMLFlipBook from 'react-pageflip';

function childProductFilter() {
  let blacklistedChildProduct = {};

  return (skuCode) => {
    if (!blacklistedChildProduct[skuCode]) {
      blacklistedChildProduct[skuCode] = true;
      return false;
    }

    return true;
  };
}
function clickHandler() {
  // Post a message to the parent window
  window.parent.postMessage(
    {
      type: "innerButtonClicked",
      message: window.location.href
    },
    "*"
  ); // Update with your actual parent window origin
}
// Example usage of sendRouteChange function (simulate route change)

const EcollatViewerref = (props, ref) => {
  const navigate = useNavigate();
  const params = useParams()
  const cartContext = useContext(CartContext);
  // static whyDidYouRender = true;
  // const [slides, setSlides] = useState([]);
  const [calc, setCalc] = useState({
    container: { x: 1920, y: 874 },
    slider: { x: 1264, y: 874 },
  });
  const [spreadCount, setSpreadCount] = useState(2);
  // const [xValue, setXValue] = useState(null);
  // const [searchActive, setSearchActive] = useState(false);
  const { zoomActive, setZoomActive } = props;
  const [doesPanMove, setDoesPanMove] = useState(false);
  const [move, setMove] = useState({ startX: 0, startY: 0 });
  const [zoom, setZoom] = useState(1.5);
  const [isOpen, setIsOpen] = useState(false);
  const [multiProducts, setMultiProducts] = useState([]);
  const [cartCounterHandler, setCartCounterHandler] = useState(false);
  const [buttonType, setButtonType] = useState("");
  const [collectionName, setCollectionName] = useState("");
  const [collectionDescription, setCollectionDescription] = useState("");
  const { pdf } = props;
  const viewPortref = useRef(null);
  const staggerref = useRef(null);
  const containerref = useRef(null);
  const shadowref = useRef(null);
  const spreadWrapref = useRef(null);
  const elementRef = useRef(null);
  const [animate, setAnimate] = useState(false);
  const [view, setView] = useState({});
  const [ismobile, setismobile] = useState(false);
  const [showleftNavigator, setshowleftNavigator] = useState(true)
  const [showrightNavigator, setshowrightNavigator] = useState(true)

  // const verticalscroll = pdf.pdfID === 292;
  // const isFlipBook = pdf.pdfID === 290;
  // const Sidebar = props.isSidebar;

  const { verticalscroll, isFlipBook, Sidebar } = props.config;
  if (props.embededlink) {
    window.onpopstate = function () {
      // Send a message to the parent window
      window.parent.postMessage({
        type: "navigateBack",
        message: window.location.href
      }, '*');
    };
  }
  let newView = {};
  // FlipSlideWrapper(
  //   page,
  //   searchData,
  //   zoom,
  //   handleModal,
  //   cartCounterHandler,
  //   singlePage
  // ) {
  //   return (
  //     <>
  //       {view.FlipSlide(
  //         page,
  //         searchData,
  //         zoom,
  //         handleModal,
  //         cartCounterHandler,
  //         singlePage
  //       )}
  //     </>
  //   );
  // }

  // handel-modal
  const handleModal = async (products, cartCounterHandler) => {
    if (products) {
      if (products?.meta?.type === "3") {
        let com_slug = window.__E_TT_Client.companySlug;
        const res = await Service.post(`public/getcollectiondata`, {
          collection_id: products?.collectionID,
          com_slug,
        }).catch((error) => {
          // Handle error
        });

        setMultiProducts(res.data?.data);
        setIsOpen(true);
        setCartCounterHandler(cartCounterHandler);
        setButtonType(products?.meta?.type);
        setCollectionName(products?.collectionName);
        setCollectionDescription(products?.collectionDescription);
      } else {
        const allSku = products.sku_code;
        let allSkuInfo = [];
        let com_slug = window.__E_TT_Client.companySlug;

        const isChildProductDuplicate = childProductFilter();

        for (let i = 0; i < allSku.length; i++) {
          const res = await Service.post(`public/getallinfobysku`, {
            sku_code: allSku[i],
            com_slug,
          }).catch((error) => {
            // Handle error
          });
          const { child_sku, ...product } = res.data.data;
          const filteredChildProduct = child_sku?.filter((childProduct) => {
            if (isChildProductDuplicate(childProduct.sku_code)) {
              return false;
            }

            return true;
          });

          allSkuInfo.push({ ...product, child_sku: filteredChildProduct });
        }

        setMultiProducts(allSkuInfo);
        setIsOpen(true);
        setCartCounterHandler(cartCounterHandler);
        setButtonType(products?.meta?.type);
      }
    } else {
      setIsOpen(false);
    }
  };

  const releaseBack = () => {
    // const { stagger } = refs;
    const stagger = staggerref.current;

    if (!stagger) return;
    const translateValue = calc.slider.x * -1;

    stagger.style.transition = `${300}ms`;
    stagger.style.webkitTransition = `${300}ms`;

    stagger.style.transform = `translateX(${translateValue}px)`;

    setTimeout(() => {
      stagger.style.transition = `${0}ms`;
      stagger.style.webkitTransition = `${0}ms`;
    }, 300);
  };

  const _onSwiping = (e) => {
    const stagger = staggerref.current;
    const {
      match: { params },
      pdf,
    } = props;
    if (!stagger) return;

    let xValue = calc.slider.x;
    if (e.dir === "Right") {
      let pageNo = view.sanitizePageNo(params.pageNo);
      pageNo = view.getNextPageNo(pageNo, 0);
      if (!pageNo || pageNo <= 0) return;
      if (isFlipBook && spreadCount === 2) {
        previousPage(pageNo, params.pageNo);
      } else {
        xValue -= e.absX;
        stagger.style.setProperty("transform", `translateX(${xValue * -1}px)`);
        stagger.style.setProperty(
          "webkitTransform",
          `webkitTranslateX(${xValue * -1}px)`
        );
      }
    }

    if (e.dir === "Left") {
      let pageNo = view.sanitizePageNo(params.pageNo);
      pageNo = view.getNextPageNo(pageNo, 1);
      if (!pageNo || pageNo > pdf.totalPages) return;
      if (isFlipBook && spreadCount === 2) {
        nextPage(pageNo, params.pageNo);
      } else {
        xValue += e.absX;
        stagger.style.setProperty("transform", `translateX(${xValue * -1}px)`);
        stagger.style.setProperty(
          "webkitTransform",
          `webkitTranslateX(${xValue * -1}px)`
        );
      }
    }
  };

  const _onSwiped = (e) => {
    // const { calc } = state;
    const {
      match: { params },
      pdf,
    } = props;
    let pos;
    let pageNo;
    let swipeDelta = 20 / 100;
    let swipeVelocity = 1.2;

    let prvXvalue = calc.slider.x;

    if (e.dir === "Left") {
      if (e.absX > prvXvalue * swipeDelta || e.velocity > swipeVelocity) {
        pos = 1;
        pageNo = view.sanitizePageNo(params.pageNo);
        pageNo = view.getNextPageNo(pageNo, pos);

        if (!pageNo || pageNo > pdf.totalPages) return;
        else slideController(pageNo, pos);
      } else releaseBack();
    }

    if (e.dir === "Right") {
      if (e.absX > prvXvalue * swipeDelta || e.velocity > swipeVelocity) {
        pos = 0;
        pageNo = view.sanitizePageNo(params.pageNo);
        pageNo = view.getNextPageNo(pageNo, pos);

        if (!pageNo || pageNo <= 0) return;
        else slideController(pageNo, pos);
      } else releaseBack();
    }
  };

  const _handleKeyPress = (e) => {
    const {
      match: { params },
      pdf,
    } = props;
    let pos;
    let pageNo;

    if (
      e &&
      (e.key === 39 ||
        e.key === "ArrowRight" ||
        e.keyCode === 39 ||
        e.which === 39)
    ) {
      pos = 1;
      pageNo = view.sanitizePageNo(params.pageNo);
      pageNo = view.getNextPageNo(pageNo, pos);

      if (!pageNo || pageNo > pdf.totalPages) return;
      else slideController(pageNo, pos);
    }

    if (
      e &&
      (e.key === 37 ||
        e.key === "ArrowLeft" ||
        e.keyCode === 37 ||
        e.which === 37)
    ) {
      pos = 0;
      pageNo = view.sanitizePageNo(params.pageNo);
      pageNo = view.getNextPageNo(pageNo, pos);

      if (!pageNo || pageNo <= 0) return;
      else slideController(pageNo, pos);
    }
  };

  const toggleZoom = (evt) => {
    console.log(evt, "evt");

    if (evt === undefined) {
      setZoomActive(false);
      return;
    }

    const slide = evt.currentTarget;
    const slideRect = slide.getBoundingClientRect(); // Get the position of the slide element
    let startX = evt.clientX - slideRect.left; // Adjust X coordinate relative to the slide element
    let startY = evt.clientY - slideRect.top;  // Adjust Y coordinate relative to the slide element
    if (zoomActive) return;

    setZoomActive(!zoomActive);
    setMove({ startX, startY });

  };

  function nextPage(newPageNo, pageNo) {
    const { pdf } = props;
    const animateSpeed = animate ? 800 : 400;
    let actualPage = document.getElementById(`page-${pageNo}`);
    let newPagechange = document.getElementById(`page-${newPageNo}`);

    setAnimate(true);
    if (pageNo % 2 !== 0 && pageNo !== "1") {
      actualPage = document.getElementById(`page-${pageNo - 1}`);
    }
    // const actualPage = document.getElementById(`page-${pageNo}`);
    if (actualPage) {
      const zNext = window.getComputedStyle(newPagechange).zIndex;
      const rightElement = actualPage.querySelector(".right-img");
      const leftElement = newPagechange.querySelector(".left-img");
      // leftElement.style.zIndex = zActual;
      leftElement.style.zIndex = 1;
      rightElement.style.zIndex = 2;
      const keyframes = [
        { transform: "rotateY(-30deg)" },
        { transform: "rotateY(-60deg)" },
        { transform: "rotateY(-90deg)" },
        { transform: "rotateY(-120deg)" },
        { transform: "rotateY(-150deg)" },
        { transform: "rotateY(-180deg)" },
      ];
      // Set up animation options
      const options = {
        duration: animateSpeed,
        transitionTimingFunction: "cubic-bezier(0.1, 0.7, 1.0, 0.1)",
        fill: "forwards", // Keeps the final state of the animation
      };

      // Apply the animation
      const bringUI = rightElement.animate(keyframes, options);
      bringUI.onfinish = (event) => {
        onAnimationEnd();
      };
      function onAnimationEnd() {
        actualPage.style.zIndex = zNext;
        // rightElement.style.transform = `rotateY(0deg)`;
        rightElement.style.zIndex = unset;
        bringUI.cancel();
        setAnimate(false);

        // setTimeout(() => {
        navigate(`/${pdf.pdfSlug}/page/${newPageNo}`);
        // }, 200);
      }
    }
  }
  function previousPage(newPageNo, pageNo) {
    const { pdf } = props;
    const animateSpeed = animate ? 1000 : 400;
    let actualPage = document.getElementById(`page-${pageNo}`);
    let newPage = document.getElementById(`page-${newPageNo}`);
    if (pageNo % 2 !== 0 && pageNo !== "1") {
      actualPage = document.getElementById(`page-${pageNo - 1}`);
    }
    if (actualPage) {
      const zActual = window.getComputedStyle(actualPage).zIndex;
      const zNext = window.getComputedStyle(newPage).zIndex;
      const leftElement = actualPage.querySelector(".left-img");
      const rightElement = newPage.querySelector(".right-img");
      newPage.style.zIndex = 1;
      rightElement.style.zIndex = 1;
      leftElement.style.zIndex = 3;
      // nextleftElement.style.zIndex = 1;
      // Set up keyframes for the animation
      // const keyframes = [
      //   { transform: "rotateY(-180deg)" },
      //   { transform: "rotateY(-150deg)" },
      //   { transform: "rotateY(-120deg)" },
      //   { transform: "rotateY(-90deg)" },
      //   { transform: "rotateY(-60deg)" },
      //   { transform: "rotateY(-30deg)" },
      // ];
      const keyframes = [
        { transform: "rotateY(30deg)" },
        { transform: "rotateY(60deg)" },
        { transform: "rotateY(90deg)" },
        { transform: "rotateY(120deg)" },
        { transform: "rotateY(150deg)" },
        { transform: "rotateY(180deg)" },
      ];
      // Set up animation options
      const options = {
        duration: animateSpeed,
        transitionTimingFunction: "cubic-bezier(0.1, 0.7, 1.0, 0.1)",
        fill: "forwards", // Keeps the final state of the animation
      };
      // setTimeout(() => {
      //   newPage.style.zIndex = 2;
      // }, 150);
      // Apply the animation
      const bringUI = leftElement.animate(keyframes, options);
      bringUI.onfinish = () => onAnimationEnd();

      function onAnimationEnd() {
        actualPage.style.zIndex = zNext;
        leftElement.style.zIndex = unset;
        bringUI.cancel();
        // setTimeout(() => {
        navigate(`/${pdf.pdfSlug}/page/${newPageNo}`);
        // }, 200);
      }
    }
  }

  const slideController = (newPageNo, pos, goto = false) => {
    const stagger = staggerref.current;
    trackPage(newPageNo);
    // const { stagger } = refs;
    const { pdf } = props;

    if (goto) {
      navigate(`/${pdf.pdfSlug}/page/${newPageNo}`);
      return;
    }

    if (isFlipBook && spreadCount === 2) {
      setAnimate(true);
      if (pos === 1) {
        nextPage(newPageNo, pageNo);
        setAnimate(false);
      } else {
        previousPage(newPageNo, pageNo);
        setAnimate(false);
      }
    } else {
      if (!stagger) return;
      const { x: width } = calc.slider;
      let animateSpeed = animate ? 350 : 400;
      let timespeed = animate ? 50 : 150;
      let translateValue = pos ? width * 2 * -1 : 0;
      setAnimate(true);
      stagger.style.transition = `${animateSpeed}ms cubic-bezier(0.645, 0.045, 0.355, 1)`;
      stagger.style.webkitTransition = `${animateSpeed}ms cubic-bezier(0.645, 0.045, 0.355, 1)`;

      stagger.style.transform = `translateX(${translateValue}px)`;

      setTimeout(() => {
        stagger.style.transition = `0ms ease-in-out`;
        stagger.style.webkitTransition = `0ms ease-in-out`;

        stagger.style.transform = `translateX(${width * -1}px)`;
        navigate(`/${pdf.pdfSlug}/page/${newPageNo}`);
        setAnimate(false);
      }, animateSpeed + timespeed);
    }
  };

  const getContainerClass = (pageNo) => {
    // const { spreadCount } = state;
    const { pdf, embededlink } = props;
    let cssClass = "slider-wrap";

    if (embededlink) {
      cssClass += " slider-wrap-embed";
    }

    pageNo = view.sanitizePageNo ? view.sanitizePageNo(pageNo) : pageNo;

    if (spreadCount === 1) cssClass += " single-page";
    else cssClass += " spread-page";

    if (view.is_touchDevice && view.is_touchDevice())
      cssClass += " touch-device";

    if (pageNo === 1) cssClass += " init-page";
    else if (pdf.totalPages % 2 !== 0 && pageNo >= pdf.totalPages)
      cssClass += " end-page";
    else if (pdf.totalPages % 2 === 0 && pageNo >= pdf.totalPages - 1)
      cssClass += " end-page";
    else cssClass += " inner-page";

    return cssClass;
  };

  const calcSlider = () => {
    const ismobile = window.innerWidth > 768 ? false : true;
    setismobile(ismobile);
    props.setismobile();
    localStorage.setItem("isMobile", ismobile);
    const { pdf, searchActive } = props;
    const { params } = props.match;
    const totalPages = pdf.totalPages;
    let pageNo =
      newView.sanitizePageNo(params.pageNo) ||
      view.sanitizePageNo(params.pageNo);
    if (pageNo < 1 || pageNo > totalPages) pageNo = 1;
    const x = pdf.imageWidth;
    const y = pdf.imageHeight;

    // const { container, viewPort, shadow, spreadWrap, stagger } = refs;
    let container = containerref.current;
    let viewPort = viewPortref.current;
    let spreadWrap = spreadWrapref.current;
    let shadow = shadowref.current;
    let stagger = staggerref.current;

    if (!container || !viewPort || !spreadWrap || !stagger) return;

    let viewAreaHeight = window.innerHeight - 50;
    let viewAreaWidth =
      searchActive && window.innerWidth > 1024
        ? window.innerWidth - 370
        : window.innerWidth;

    // Responsive Calc
    const gutterSpace = window.innerWidth > 768 ? 110 : 0;

    const spreadCount = newView.spreadCount() || view.spreadCount();

    let imgHeight = viewAreaHeight > y ? y : viewAreaHeight - 30;
    let imgWidth = viewAreaHeight > y ? x : Math.round(imgHeight * (x / y));

    // Odd Page Size Check
    if (imgWidth % 2 !== 0) {
      imgWidth -= 1;
      imgHeight = Math.round(imgWidth * (y / x));
    }

    // Sufficient Width For SLider Check
    if (imgWidth * spreadCount + gutterSpace > viewAreaWidth) {
      imgWidth = (viewAreaWidth - gutterSpace) / spreadCount - 5 * 2;
      imgHeight = Math.round(imgWidth * (y / x));
    }

    let xValue = imgWidth * spreadCount;
    container.style.width = viewAreaWidth + "px";

    spreadWrap.style.width = imgWidth * spreadCount + gutterSpace + "px";
    spreadWrap.style.paddingTop =
      (window.innerHeight - 50 - imgHeight) / 2 + "px";

    shadow.style.maxWidth = imgWidth * spreadCount + "px";
    shadow.style.height = imgHeight + "px";

    if (!isFlipBook || spreadCount !== 2) {
      stagger.style.setProperty("transform", `translateX(${xValue * -1}px)`);
      stagger.style.setProperty(
        "webkitTransform",
        `webkitTranslateX(${xValue * -1}px)`
      );
    } else {
      stagger.style.removeProperty("transform", `translateX(${0}px)`);
      stagger.style.removeProperty(
        "webkitTransform",
        `webkitTranslateX(${0}px)`
      );
    }

    viewPort.style.width = imgWidth * spreadCount + "px";
    viewPort.style.maxWidth = imgWidth * spreadCount + "px";
    viewPort.style.height = imgHeight + "px";

    const dimension = {
      slider: {
        x: imgWidth * spreadCount,
        y: imgHeight,
      },
      container: {
        x: viewAreaWidth,
        y: imgHeight,
      },
    };

    if (newView.setDimension) {
      newView.setDimension(dimension);
    } else {
      view.setDimension(dimension);
    }

    setCalc(dimension);

    setSpreadCount(spreadCount);
    // setXValue(xValue);
  };

  const handlers = useSwipeable({
    onSwiping: _onSwiping,
    onSwiped: _onSwiped,
  });
  const getSlides = () => {
    const { pageNo } = params;
    const { singlePage } = props.pdf;
    const { searchResult } = props;
    // const { calc } = state;
    if (!view.sanitizePageNo) return null;

    let currentPage = view.sanitizePageNo(pageNo);
    let prevPage = view.getNextPageNo(currentPage, 0);
    let nextPage = view.getNextPageNo(currentPage, 1);
    let pageview = true;
    return (
      <div {...handlers}>
        {view.getSlide(
          prevPage,
          searchResult,
          1,
          handleModal,
          cartContext,
          singlePage,
          false
        )}
        {view.getSlide(
          currentPage,
          searchResult,
          true,
          handleModal,
          cartContext,
          singlePage,
          pageview
        )}
        {view.getSlide(
          nextPage,
          searchResult,
          1,
          handleModal,
          cartContext,
          singlePage,
          false
        )}
      </div>
    );
  };
  const getFlipSlides = () => {
    const { pageNo } = params;
    const { singlePage } = props.pdf;
    const { searchResult } = props;
    // const { calc } = state;
    if (!view.sanitizePageNo) return null;

    let currentPage = view.sanitizePageNo(pageNo);
    let prevPage = view.getNextPageNo(currentPage, 0);
    let nextPage = view.getNextPageNo(currentPage, 1);
    let pageview = true;

    return (
      <div {...handlers}>
        {view.FlipSlide(
          prevPage,
          searchResult,
          1,
          handleModal,
          cartContext,
          singlePage,
          false,
          animate
        )}
        {view.FlipSlide(
          currentPage,
          searchResult,
          true,
          handleModal,
          cartContext,
          singlePage,
          pageview,
          animate
        )}
        {view.FlipSlide(
          nextPage,
          searchResult,
          1,
          handleModal,
          cartContext,
          singlePage,
          false,
          animate
        )}
      </div>
    );
  };
  const trackPage = (page) => {
    const { pageNo } = params;
    const { pdf } = props;
    page = Number(page || pageNo);
    const count = view.spreadCount ? view.spreadCount() : newView.spreadCount();
    let url = `/${pdf.pdfSlug}/page/${page}`;
    // trackPageGoogleAnalytics(url);
    if (window.__E_TT_Client?.gtm) {
      trackPageGoogleTagManager(url);
      trackPageGoogleAnalytics(url, pdf.pdfmetaTitle, page);
    }
    PageViewAnalatics({
      pdfID: pdf.pdfID,
      pageno: page,
      time: 1,
      revisted: false,
      url: url,
      clientID: pdf.clientID
    })
    if (count > 1 && page !== 1 && page < pdf.totalPages) {
      let newPage = Number(page) + 1;
      url = `/${pdf.pdfSlug}/page/${newPage}`;
      // trackPageGoogleAnalytics(url);
      if (window.__E_TT_Client?.gtm) {
        trackPageGoogleTagManager(url);
        trackPageGoogleAnalytics(url, pdf.pdfmetaTitle, page);
      }
      PageViewAnalatics({
        pdfID: pdf.pdfID,
        pageno: newPage,
        time: 1,
        revisted: false,
        url: url,
        clientID: pdf.clientID
      })
    }
  };




  const { hotSpot, products, togglePopup, productdetail } = props;

  useEffect(() => {
    const events = {
      slideController: slideController,
      zoomController: toggleZoom,
      popupController: togglePopup,
      productdetail: productdetail,
    };

    const jsonData = {
      linkData: hotSpot,
      productData: products,
    };

    if (pdf) {
      newView = new ecollatHelper(pdf, jsonData, events);
      // view=newView
      setView(newView);
      calcSlider();
      trackPage();
    }

    const handleResize = () => calcSlider();

    window.addEventListener("resize", handleResize, false);
    window.addEventListener("keyup", _handleKeyPress, false);

    return () => {
      window.removeEventListener("resize", handleResize, false);
      window.removeEventListener("keyup", _handleKeyPress, false);
    };
  }, [pdf, hotSpot, products, togglePopup, productdetail]);

  const clickOrDrag = e => {
    console.log(move);
    if (!doesPanMove) {
      setZoomActive(false)
      setZoom(1.5)
    }
    if (
      move.startX === e.x &&
      move.startY === e.y
    ) {
      setZoomActive(false)
      setZoom(1.5)
      setDoesPanMove(false)
    } else {
      setMove({
        startX: e.x,
        startY: e.y
      })

    }
  };
  // const _renderPan = () => {
  //   const { pageNo } = params;
  //   const { searchResult } = props;

  //   let config = {
  //     width: window.innerWidth,
  //     height: window.innerHeight,
  //     ...move,
  //     onPan: () => setDoesPanMove(true),
  //     onPanStart: () => setDoesPanMove(true),
  //     onPanStop: (e) => {
  //       if (doesPanMove) {
  //         // If pan movement is detected, delay closing
  //         setTimeout(() => setDoesPanMove(false), 200);
  //       } else {
  //         // If no pan movement, close immediately
  //         setDoesPanMove(false);
  //       }
  //     },
  //   };

  //   return (
  //     <Panview {...config}>{view.getSlide(pageNo, searchResult, 1.8)}</Panview>
  //   );
  // };
  const _renderPan = () => {
    const { pageNo } = props.match.params;
    const { searchResult } = props;

    let config = {
      width: window.innerWidth,
      height: window.innerHeight,
      startX: move.startX,
      startY: move.startY,
      onPan: () => setDoesPanMove(true),
      onPanStop: (e) => {
        if (!doesPanMove) {

        }
        clickOrDrag(e)
      },
    };
    return (
      <Panview {...config}>
        <div className={calc.slider.x * zoom <= window.innerWidth && "ev_zoom_center"}>
          {view.getSlide(pageNo, searchResult, zoom)}
        </div>
      </Panview>

    );
  };
  const _render = () => {
    return (
      <div className="slider" ref={viewPortref} style={{ overflow: isFlipBook ? "unset" : "hidden" }}>
        <div className="slide-stagger" ref={staggerref}>
          {isFlipBook ? getFlipSlides() : getSlides()}
          {/* {getflipSlides()} */}
        </div>
      </div>
    );
  };

  const showSliderpagesPages = () => {
    const {
      pdf,
      pdf: { totalPages },
    } = props;
    if (!view.sanitizePageNo) return null;

    return Array.from({ length: totalPages }, (_, i) => i + 1).map((i) => {
      let divClass = "page-thumb Slide-view";
      if (isTouchDevice()) divClass += " has-touch";
      const { singlePage } = props.pdf;
      const { searchResult } = props;
      const { pageNo } = params;
      var preview =
        parseInt(pageNo) === i ||
        parseInt(pageNo) === i + 1 ||
        parseInt(pageNo) === i - 1;
      setTimeout(() => {
        if (preview) {
          preview = false;
        }
      }, 200);
      return (
        <div className={divClass} key={i}>
          {view.ScrollSlider(
            i,
            searchResult,
            1,
            handleModal,
            cartContext,
            singlePage,
            preview
          )}
        </div>
      );
    });
  };
  const handleScroll = () => {
    const element = elementRef.current;
    const urlParams = new URLSearchParams(window.location.search);
    const scrollValue = urlParams.get("scroll");
    if (element && scrollValue !== "true") {
      const scrollPosition = element.scrollTop <= 0 ? 0 : element.scrollTop;
      const totalScrollHeight = element.scrollHeight - element.clientHeight;

      const sections = document.querySelectorAll(".Scrollsection");

      sections.forEach((section) => {
        const rect = section.getBoundingClientRect();
        const sectionTop = rect.top + scrollPosition + 140;
        const sectionHeight = section.clientHeight;

        if (
          scrollPosition >= sectionTop - sectionHeight &&
          scrollPosition < sectionTop
        ) {
          const classList = section.id;
          const sectionNumber = classList.toString().match(/\d+/);
          const endPoint = sectionNumber ? parseInt(sectionNumber[0], 10) : 0;

          // Check if we are at the end of the page
          const atEnd = Math.abs(scrollPosition - totalScrollHeight) < 10;
          if (atEnd) {
            setTimeout(() => {
              trackPage(pdf.totalPages);
              // const newUrl = `/${pdf.pdfSlug}/page/${pdf.totalPages}`;
              // window.history.replaceState({}, document.title, newUrl);
              navigate(`/${pdf.pdfSlug}/page/${pdf.totalPages}`, { replace: true });

            }, 20);
            // Add your logic for handling the end of the page here
          }
          trackPage(endPoint);
          // const newUrl = `/${pdf.pdfSlug}/page/${endPoint}`;
          // window.history.replaceState({}, document.title, newUrl);
          navigate(`/${pdf.pdfSlug}/page/${endPoint}`, { replace: true });
        }
      });
    }
  };

  const debouncedHandleScroll = debounce(handleScroll); // Adjust debounce delay as needed

  useEffect(() => {
    if (ismobile && verticalscroll) {
      const element = elementRef.current;
      if (element) {
        element.addEventListener("scroll", debouncedHandleScroll);

        return () => {
          // Cleanup the event listener and cancel debounced function when the component is unmounted
          element.removeEventListener("scroll", debouncedHandleScroll);
          debouncedHandleScroll.cancel();
        };
      }
    }
  }, [debouncedHandleScroll]);

  useEffect(() => {
    const scrollToTargetElement = () => {
      const { pageNo } = params;
      const targetElement = document.querySelector("#Scrollsection" + pageNo);
      const urlParams = new URLSearchParams(window.location.search);
      const scrollValue = urlParams.get("scroll");

      if (targetElement && scrollValue === "true") {
        targetElement.scrollIntoView({
          behavior: "smooth", // Optional: Adds smooth scrolling effect
          block: "start", // Scrolls to the top of the target element
          inline: "nearest", // Optional: Aligns the target element to the nearest edge of the scroll container
        });
        setTimeout(() => {
          const urlParams = new URLSearchParams(window.location.search);
          if (urlParams.has("scroll")) {
            urlParams.delete("scroll");
            const newUrl = `${window.location.pathname
              }?${urlParams.toString()}`;
            window.history.replaceState({}, document.title, newUrl);
          }
        }, 1000);
      }
    };
    if (ismobile && verticalscroll) {
      scrollToTargetElement();
    }
  }, [ismobile, params]);




  const trackPageSecond = () => {
    const { pageNo } = params;
    const { pdf } = props;
    var page = Number(pageNo);
    const count = view.spreadCount ? view.spreadCount() : newView.spreadCount();
    let url = `/${pdf.pdfSlug}/page/${page}`;
    // trackPageGoogleAnalytics(url);
    PageViewAnalatics({
      pdfID: pdf.pdfID,
      pageno: page,
      time: 5,
      revisted: true,
      url: url,
      clientID: pdf.clientID
    })
    if (count > 1 && page !== 1 && page < pdf.totalPages) {
      let newPage = Number(page) + 1;
      url = `/${pdf.pdfSlug}/page/${newPage}`;
      // trackPageGoogleAnalytics(url);
      PageViewAnalatics({
        pdfID: pdf.pdfID,
        pageno: newPage,
        time: 5,
        revisted: true,
        url: url,
        clientID: pdf.clientID
      })
    }
  };


  useEffect(() => {
    const intervalId = setInterval(() => {
      trackPageSecond();
    }, 5000);
    return () => {
      clearInterval(intervalId);
    };
  }, []);









  useEffect(() => {
    if (isFlipBook && spreadCount === 2) {
      if (params.pageNo === pdf.totalPages) {
        setshowrightNavigator(false)
        setshowleftNavigator(true)
      } else {
        setshowrightNavigator(true)
        setshowleftNavigator(true)

      }
    }
  }, [isFlipBook, params.pageNo, spreadCount])



  useEffect(() => {
    const scrollToTargetElement = () => {
      const { pageNo } = params;
      const targetElement = document.querySelector("#Scrollsection" + pageNo);

      if (targetElement) {
        targetElement.scrollIntoView({
          behavior: "smooth", // Optional: Adds smooth scrolling effect
          block: "start", // Scrolls to the top of the target element
          inline: "nearest", // Optional: Aligns the target element to the nearest edge of the scroll container
        });
        setTimeout(() => {
          const urlParams = new URLSearchParams(window.location.search);
          if (urlParams.has("scroll")) {
            urlParams.delete("scroll");
            const newUrl = `${window.location.pathname
              }?${urlParams.toString()}`;
            window.history.replaceState({}, document.title, newUrl);
          }
        }, 1000);
      }
    };

    setTimeout(() => {
      if (ismobile && verticalscroll) {
        scrollToTargetElement();
      }
    }, 1200);
  }, [ismobile]);

  const { pageNo } = params;
  const pdfmetaTitle = props?.location?.state?.pdfData?.pdfmetaTitle;
  const pdfmetaDesc = props?.location?.state?.pdfData?.pdfmetaDesc;
  if (pdfmetaTitle !== undefined && pdfmetaDesc !== undefined) {
    localStorage.setItem("pdfmetaTitle", pdfmetaTitle);
    localStorage.setItem("pdfmetaDesc", pdfmetaDesc);
  }
  return (
    <React.Fragment>
      <Helmet>
        {/* <title>{localStorage.getItem("pdfmetaTitle")}</title> */}
        <title>{pdf?.pdfmetaTitle}</title>
        {/* <meta name="description" content={localStorage.getItem("pdfmetaDesc")} /> */}
        <meta name="description" content={pdf?.pdfmetaDesc} />
        <meta name="og:image" content={pdf?.pdfcoverPath} />
      </Helmet>

      {ismobile && verticalscroll && (
        <div
          className="epaper-custompdf"
          style={{
            visibility: `${ismobile && verticalscroll ? "visible" : "hidden"}`,
          }}
        >
          {zoomActive && _renderPan()}
          <div className="pdf-wrapper">
            <div className="page-selector Slide-view" ref={elementRef}>
              {showSliderpagesPages()}
            </div>
          </div>
        </div>
      )}

      <div
        className="e-viewer"
        style={{
          visibility: `${!ismobile ? "visible" : verticalscroll ? "hidden" : "visible"
            }`,
          marginLeft: `${Sidebar ? "1.5rem" : 0}`,
        }}
      >
        {zoomActive && _renderPan()}
        {zoomActive && <ZoomControls setZoom={setZoom} zoom={zoom} setZoomActive={setZoomActive} />}
        <div
          className={`slider-container ${props.embededlink && "flex-direction-coloum"
            }`}
          ref={containerref}
        >
          <div className={getContainerClass(pageNo)} ref={spreadWrapref}>
            <div className={`ecollat-shadow ${isFlipBook ? "stopshadow" : ""}`} ref={shadowref} />

            {showleftNavigator && view.getNavigation && view.getNavigation(pageNo, 0)}

            {_render()}
            {showrightNavigator && view.getNavigation && view.getNavigation(pageNo, 1)}
          </div>
          {props.embededlink && (
            <div
              style={{
                width: calc?.slider?.x,
                height: "45px",
                display: "flex",
                justifyContent: (parseInt(params.pageNo) === pdf.totalPages && (pdf.totalPages - 1) % 2 !== 0) && spreadCount === 2 ? "flex-start" : "flex-end"
              }}
            >
              <div
                className="Embed-bottom"
                id="embed_controls"
                style={{
                  width:
                    (params.pageNo === "1" || (parseInt(params.pageNo) === pdf.totalPages && (pdf.totalPages - 1) % 2 !== 0)) && spreadCount === 2
                      ? calc?.slider?.x / 2
                      : calc?.slider?.x,

                }}
                onClick={() => clickHandler()}
              >
                <button>Open</button>
              </div>
            </div>
          )}
        </div>

        {/* <div ref="container">
                        <div className={getContainerClass(pageNo)} ref="spreadWrap">
                            <div className="ecollat-shadow" ref="shadow" />

                            {view.getNavigation && view.getNavigation(pageNo, 0)}

                            {_renderFilip()}
                            {view.getNavigation && view.getNavigation(pageNo, 1)}
                        </div>
                    </div> */}
      </div>

      {isOpen && (
        <>
          <Modal
            handleModal={handleModal}
            multiProducts={multiProducts}
            pdf={props.pdf}
            cartCounterHandler={cartCounterHandler}
            buttonType={buttonType}
            collectionName={collectionName}
            collectionDescription={collectionDescription}
          />
          <div
            onClick={() => handleModal()}
            className={`modal-backdrop ${isOpen ? "show" : "fade"}`}
          ></div>
        </>
      )}
    </React.Fragment>
  );
};
const EcollatViewer = React.forwardRef(EcollatViewerref);

export default EcollatViewer;
// export default EcollatViewer;
