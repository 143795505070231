import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

const PdfList = ({ data }) => {
  const navigate = useNavigate();
  const handleShowPdf = (isShowCart, lowrise, pdfmetaDesc, pdfmetaTitle) => {
    if (pdfmetaTitle !== "" && pdfmetaTitle) {
      document.title = pdfmetaTitle;
    } else if (pdfmetaDesc !== "" && pdfmetaDesc) {
      document.title = pdfmetaDesc;
    }
    localStorage.setItem("isShowCart", isShowCart);
    localStorage.setItem("isLowrise", lowrise);
  };

  const renderItem = (item) => {
    ;
    const route = () => {
      navigate(`/${item.pdfSlug}/page/1`, {
        state: {
          pdfData: item,
        },
      });
    };

    return (
      <div
        className="column column-3 column-6"
        key={item.pdfID}
        onClick={() =>
          handleShowPdf(
            item.showCart,
            item.lowrise,
            item.pdfmetaDesc,
            item.pdfmetaTitle
          )
        }
      >
        <Link
          className="pdf-item"
          to={`/${item.pdfSlug}/page/1`}
          state={{ pdfData: item }}
        >
          <div className="cover-img">
            <img src={item.pdfcoverPath} alt={item.titleName} />
          </div>
          <div className="pdf-title">
            <h2>{item.titleName}</h2>
          </div>
        </Link>
      </div>
    );
  };

  const renderNodata = () => {
    return (
      <div className="empty-wrapper">
        <h2>No Collateral Found!</h2>
      </div>
    );
  };

  const renderData = () => {
    if (data && data.length > 0) {
      return data.map(renderItem);
    } else return renderNodata();
  };

  return (
    <div className="container">
      <div className="row forPrivacyPolicyAndCookie">{renderData()}</div>
    </div>
  );
};

export default PdfList;
