import React, { useEffect, useState, useRef } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { GlobalSearchContext } from "./../Components/Viewer/context";
import Layout from "./layout";
import Header from "./header-v2";
import { getAllPdf } from "../Action";
import Banner from "../Components/Banner";
import Loader from "../Components/Loader";
import { Helmet } from "react-helmet";
import { SubDomainRouter } from "../Routes";
import { BrowserRouter as Router } from 'react-router-dom';

const App = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [appLoading, setAppLoading] = useState(true);
  const [windowHeight, setWindowHeight] = useState(null);
  const [pdfData, setPdfData] = useState(null);
  const [appError, setAppError] = useState(undefined);
  const [searchResult, setSearchResult] = useState(null);
  const [tagOptions, setTagOptions] = useState([]);
  const [isShowCartIcon, setIsShowCartIcon] = useState(false);

  const updateWindowHeight = () => {
    setWindowHeight(window.innerHeight);
  };

  const init = async (clientInfo) => {
    try {
      var pdfData = []
      if (!window.__E_TT_Client.isHome) {
        pdfData = await getAllPdf(clientInfo.companySlug);

      }
      setPdfData(pdfData);
      setIsLoading(false);
      setAppError(!pdfData || !pdfData.length ? true : false);
      setTimeout(() => setAppLoading(false), 1800);
      if (pdfData.length > 0) {
        localStorage.setItem("isShowCart", pdfData && pdfData[0]?.showCart);
        setIsShowCartIcon(pdfData && pdfData[0]?.showCart);
      }
    } catch (error) {
      console.error("Error initializing:", error);
    }
  };

  const createTag = (tag) => {
    setTagOptions((prevState) => [
      ...prevState,
      { label: tag, value: "" },
    ]);
  };

  const searchResultHandler = (searchResult) => {
    setSearchResult(searchResult);
  };
  const CartResultHandler = (cart) => {
    setIsShowCartIcon(cart);
  };
  useEffect(() => {
    const clientInfo = window.__E_TT_Client;
    const root = document.documentElement;

    const handleResize = () => {
      updateWindowHeight();
    };

    root.style.setProperty("--theme-color", clientInfo.primary);
    setWindowHeight(`${window.innerHeight}px`);

    init(clientInfo);

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []); // Empty dependency array to run the effect only once on mount

  const searchParams = new URLSearchParams(window.location.search);
  const embedValue = searchParams.get("_embed");
  const _transparent = searchParams.get("_transparent");
  const isTransparent = _transparent === "true" ? true : false;
  const embededlink = embedValue === "embedded" ? false : true;
  const GATag = window.__E_TT_Client?.ga;

  if (isLoading) {
    return (
      <div className="wrap">
        <div className="loader"></div>
      </div>
    );
  }

  return (
    <GlobalSearchContext.Provider
      value={{
        searchResult,
        handleSearchResult: searchResultHandler,
        createTag,
        tagOptions,
        isShowCartIcon,
        setIsShowCartIcon:CartResultHandler
      }}
    >
      <Helmet>
        <title>{document?.title}</title>

        {GATag && (
          <script
            async
            src={`https://www.googletagmanager.com/gtag/js?id=${GATag}`}
          ></script>
        )}
        {GATag && (
          <script>
            {`window.dataLayer = window.dataLayer || [];
						function gtag(){dataLayer.push(arguments);}
						gtag('js', new Date());
						gtag('config', '${GATag}');`}
          </script>
        )}
      </Helmet>

      <Banner showContent={!embededlink ? !embededlink : !appLoading} />

      <div id="wrapper" className={isTransparent && "bg-transparent"} style={windowHeight ? { height: windowHeight } : {}}>
        <Router>
          <SubDomainRouter pdfList={pdfData} />
        </Router>
      </div>

      <ToastContainer
        position="bottom-left"
        autoClose={2000}
        closeOnClick
        pauseOnVisibilityChange
        draggable
        pauseOnHover
        progressClassName="toast-progress-style"
      />
    </GlobalSearchContext.Provider>
  );
};

export default App;
export { Layout, Header };
