import React, { useState } from "react";

const ZoomControls = ({ setZoom, zoom, setZoomActive }) => {
    var zoomvalue = 0.2
    const IncreaseZoom = () => {
        if (zoom + zoomvalue > 3) return
        setZoom(zoom + zoomvalue)
    }
    const DecreaseZoom = () => {
        if (zoom - zoomvalue < 1.4) {
            setZoomActive(false)
            setZoom(1.5)
            return
        }
        setZoom(zoom - zoomvalue)
    }

    return (
        <>

            <div id="zoom-controls">
                <div id="in" button class="c-button" onClick={IncreaseZoom}>
                    <div class="c-ripple js-ripple">
                        <span class="c-ripple__circle"></span>
                        <svg id="plus-svg" width="25" height="25" viewBox="0 0 25 25" xmlns="http://www.w3.org/2000/svg"><path d="M13.5 11.5v-4h-2v4h-4v2h4v4h2v-4h4v-2h-4z" fill="#ffff" fill-rule="evenodd" /></svg>
                    </div>
                </div>
                <div id="out" button class="c-button" onClick={DecreaseZoom}>
                    <div class="c-ripple js-ripple">
                        <span class="c-ripple__circle"></span>
                        <svg id="minus-svg" width="25" height="25" viewBox="0 0 25 25" xmlns="http://www.w3.org/2000/svg"><title>Minus</title><path d="M11.5 7.5h2v10h-2z" fill="#ffff" fill-rule="evenodd" /></svg>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ZoomControls;
