import React, { useState } from "react";
import { Link } from "react-router-dom";
import { ecollatHelper } from "../../Functions";
import { svgIcon } from "../../Functions/index";
import _ from "lodash";

const SearchResult = (props) => {
  const [showTab, setShowTab] = useState({});

  const multiSearchHandler = (result, slug, pdf) => {
    let _currentPdf = pdf || props.pdf;
    let prvPage = [];
    let pageNo;
    const view = new ecollatHelper(_currentPdf);

    return result.map((item) => {
      if (window.innerWidth > window.innerHeight) {
        pageNo = view.sanitizePageNo(item.pageNo);

        if (prvPage.includes(pageNo)) return null;
        else prvPage.push(pageNo);

        return (
          <div className="result-item spread" key={pageNo}>
            <Link to={`/${slug}/page/${pageNo}`}>
              <img src={view.getImage(pageNo, 2, false)} alt="left" />
              <img src={view.getImage(pageNo + 1, 2, false)} alt="left" />
            </Link>
          </div>
        );
      } else {
        return (
          <div
            className="result-item"
            key={pageNo}
            onClick={() => props.closeSearch()}
          >
            <Link to={`/${slug}/page/${pageNo}`}>
              <img src={view.getImage(pageNo, 2, false)} alt="left" />
            </Link>
          </div>
        );
      }
    });
  };

  const _renderRes = (result, slug, pdf) => {
    let _currentPdf = pdf || props.pdf;
    let prvPage = [];
    const view = new ecollatHelper(_currentPdf);

    return _.map(result, (s, pageNo) => {
      if (window.innerWidth > window.innerHeight) {
        pageNo = view.sanitizePageNo(pageNo);

        if (prvPage.includes(pageNo)) return null;
        else prvPage.push(pageNo);

        return (
          <div className="result-item spread" key={pageNo}>
            <Link to={`/${slug}/page/${pageNo}`}>
              <img src={view.getImage(pageNo, 2, false)} alt="left" />
              <img src={view.getImage(pageNo + 1, 2, false)} alt="left" />
            </Link>
          </div>
        );
      } else {
        return (
          <div
            className="result-item"
            key={pageNo}
            onClick={() => props.closeSearch()}
          >
            <Link to={`/${slug}/page/${pageNo}`}>
              <img src={view.getImage(pageNo, 2, false)} alt="left" />
            </Link>
          </div>
        );
      }
    });
  };

  const _render = (result, pdf) => {
    const _currentPdf = pdf || props.pdf;
    const { pdfSlug } = _currentPdf;
    const _resultType = Array.isArray(result);

    if (!result) return null;

    if (pdf && pdf.pages) {
      var _x;
      let _customPdfResult = {};

      for (_x in result) {
        if (pdf.pages.includes(` ${_x}`)) {
          _customPdfResult = {
            ..._customPdfResult,
            [_x]: result[_x],
          };
        }
      }

      if (Object.keys(_customPdfResult).length > 0) {
        return (
          <div className="search-results">
            {multiSearchHandler(_customPdfResult, pdfSlug, pdf)}
          </div>
        );
      } else {
        return (
          <div className="search-results">
            <h2>No search result found!</h2>
          </div>
        );
      }
    }

    if (typeof result === "object" && result.error) {
      return (
        <div className="search-results">
          <h2>{result.message || "Something Went Wrong!"}</h2>
        </div>
      );
    }

    if (typeof result === "object" && Object.keys(result).length <= 0) {
      return (
        <div className="search-results">
          <h2>No search result found!</h2>
        </div>
      );
    }

    if (_resultType) {
      return (
        <div className="search-results">
          {multiSearchHandler(result, pdfSlug, pdf)}
        </div>
      );
    } else {
      if (Object.keys(result).length > 0) {
        return (
          <div className="search-results">
            {_renderRes(result, pdfSlug, pdf)}
          </div>
        );
      }
    }

    return null;
  };

  const openTab = (id) => {
    setShowTab((prev) => ({ [id]: !prev[id] }));
  };
  return (
    <div
      className={`search-slider ${
        props.result && props.isSearchOpen ? "show" : ""
      }`}
    >
      {props.result &&
        (Array.isArray(props.result) ? (
          props.result.length > 0 ? (
            props.result.map((item) => (
              <div className="search-accordion" key={item.pdf_id}>
                <div className="head" onClick={() => openTab(item.pdf_id)}>
                  <span>{item.pdf ? item.pdf.titleName : ""}</span>
                  <span
                    className={`arrow-icon ${showTab[item.pdf_id] && "active"}`}
                  >
                    {svgIcon("ico-arrowRight")}
                  </span>
                </div>
                <div className={`body ${showTab[item.pdf_id] && "active"}`}>
                  {_render(item.data, item.pdf)}
                </div>
              </div>
            ))
          ) : (
            <div className="search-results">
              <h2>No search result found!</h2>
            </div>
          )
        ) : (
          _render(props.result, props.pdf)
        ))}
    </div>
  );
};

export default SearchResult;
