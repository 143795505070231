import cookie from 'react-cookies';
import {
  uuidv4,
} from './index';
import { ANALATICS_API_URL } from '../config';

const VISIT_SESSION = "__ecollat_session_V"

export const checkVisitSession = () => {
  const existingSession = cookie.load(VISIT_SESSION);
  if (existingSession) {

    return {
      sessionID: existingSession,
      revisited: true
    }

  };

  try {
    let cartID = {
      token: uuidv4(),
      timeStamp: new Date().valueOf()
    };

    cartID = JSON.stringify(cartID);
    cartID = window.btoa(cartID);

    const expires = new Date();
    expires.setTime(expires.getTime() + 30 * 60 * 1000); // 30 minutes from now

    cookie.save(VISIT_SESSION, cartID, {
      path: '/',
      expires
    });

    return {
      sessionID: cartID,
      revisited: false
    }

  } catch (e) {
    return false;
  }
}

export const detectBrowserAndDevice = () => {
  const UserAgent = navigator.userAgent;
  const device = {
    iPad: /iPad/.test(UserAgent),
    iPhone: /iPhone/.test(UserAgent),
    Android: /Android/.test(UserAgent),
    Windows: /Windows/.test(UserAgent),
    macOS: /Macintosh/.test(UserAgent),
    Linux: /Linux/.test(UserAgent),
    BlackBerry: /BlackBerry/.test(UserAgent),
    iPod: /iPod/.test(UserAgent),
    Samsung: /Samsung/.test(UserAgent),
    Nokia: /Nokia/.test(UserAgent),
    Sony: /Sony/.test(UserAgent),
    HTC: /HTC/.test(UserAgent),
    LG: /LG/.test(UserAgent),
    Motorola: /Motorola/.test(UserAgent),
    Nexus: /Nexus/.test(UserAgent),
    Kindle: /Kindle/.test(UserAgent),
    FirePhone: /Fire Phone/.test(UserAgent)
  };

  let browserName;

  // Checking for browser
  if (/Firefox\//.test(UserAgent)) {
    browserName = "Mozilla Firefox";
  } else if (/Edg\//.test(UserAgent)) {
    browserName = "Microsoft Edge";
  } else if (/Chrome\//.test(UserAgent)) {
    browserName = "Google Chrome";
  } else if (/Safari\//.test(UserAgent)) {
    browserName = "Apple Safari";
  } else if (/OPR\//.test(UserAgent)) {
    browserName = "Opera";
  } else if (/Trident\//.test(UserAgent)) {
    browserName = "Microsoft Internet Explorer";
  } else if (/UCBrowser\//.test(UserAgent)) {
    browserName = "UC Browser";
  } else if (/SamsungBrowser\//.test(UserAgent)) {
    browserName = "Samsung Internet";
  } else if (/MQQBrowser\//.test(UserAgent)) {
    browserName = "QQ Browser";
  } else if (/WeChat\//.test(UserAgent)) {
    browserName = "WeChat Browser";
  } else if (/Yandex\//.test(UserAgent)) {
    browserName = "Yandex Browser";
  } else if (/AppleWebKit\//.test(UserAgent)) {
    browserName = "WebKit-based Browser (unknown)";
  } else {
    browserName = "Unknown Browser";
  }
  var navigator_info = window.navigator;
  var screen_info = window.screen;
  var uid = navigator_info.mimeTypes.length;
  uid += navigator_info.userAgent.replace(/\D+/g, '');
  uid += navigator_info.plugins.length;
  uid += screen_info.height || '';
  uid += screen_info.width || '';
  uid += screen_info.pixelDepth || '';
  // Constructing the result object
  const result = {
    devicename: browserName + Object.keys(device).find(dev => device[dev]) || "Unknown Device",
    deviceid: uid,
    domain: window.location.origin,
    // companySlug:window.___E_TT_Client.companySlug
  };

  return result;
}


// ======================================================= Page View =====================================================================

export const PageViewAnalatics = async (data) => {
  return true
  var browserdata = detectBrowserAndDevice();
  var Session = checkVisitSession()
  try {
    const response = await fetch(`${ANALATICS_API_URL}/pageview`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        ...browserdata,
        ...data,
        sessionID: Session.sessionID,
        revisited: localStorage.getItem("revisit") === "true" ? true : false
      }),
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    localStorage.setItem("revisit", false)
    const result = await response.json();

    // Process the result and return or render something based on it
  } catch (error) {
    console.error('Fetch error:', error);
  }
};

// ======================================================= Page View =====================================================================


// ======================================================= Page Vistor ===================================================================


export const HomePageView = async (data) => {
  return true
  var browserdata = detectBrowserAndDevice()
  try {
    const response = await fetch(`${ANALATICS_API_URL}/visit`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        ...browserdata,
        ...data
      }),
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const result = await response.json();

    // Process the result and return or render something based on it
    console.log(result);
  } catch (error) {
    console.error('Fetch error:', error);
  }
};


export const VisitPDF = async (data) => {
  return true
  var browserdata = detectBrowserAndDevice()
  try {
    var oldpdf = JSON.parse(localStorage.getItem("pdf")) || [];

    if (oldpdf.includes(data.pdfID)) {
      return
    }



    const response = await fetch(`${ANALATICS_API_URL}/pdf/visit`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        ...browserdata,
        ...data
      }),
    });

    // Add the new PDF ID to the list
    var list = [...oldpdf, data.pdfID];

    // Save the updated list back to local storage
    localStorage.setItem("pdf", JSON.stringify(list));
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const result = await response.json();

    // Process the result and return or render something based on it
    console.log(result);
  } catch (error) {
    console.error('Fetch error:', error);
  }
}

// ======================================================= Page Vistor ===================================================================


export const FailerLog = async (data) => {
  return true

  var browserdata = detectBrowserAndDevice();
  var Session = checkVisitSession()
  try {
    const response = await fetch('/error', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        ...browserdata,
        ...data,
        sessionID: Session.sessionID,
      }),
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    localStorage.setItem("revisit", false)
    const result = await response.json();

    // Process the result and return or render something based on it
    console.log(result);
  } catch (error) {
    console.error('Fetch error:', error);
  }
};