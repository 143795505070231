// old sidbar before submenu uncomment this
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { svgIcon } from '../../Functions';
import BottomNavigation from './bottom-navigation';

const Sidebar = (props) => {
  const [openAccordion, setOpenAccordion] = useState(null);
  const navigate = useNavigate()
  const toggleAccordion = (index) => {
    setOpenAccordion((prev) => (prev === index ? null : index));
  };

  const gotoPage = (pageNo) => {
    const { history, pdf } = props;
    const { pdfSlug } = pdf;

    pageNo = Number(pageNo);
    let Url = `/${pdfSlug}/page/${pageNo}`;

    navigate(Url);

    props.toggleSidebar();
  };

  const renderMenu = () => {
    const { menuData } = props;

    if (!menuData || typeof menuData !== 'object') return null;

    return menuData.map((m, i) => {
      if (m.children && m.children.length > 0) {
        let children = m.children.map((c, j) => (
          <li key={`c_${i}_${j}`}>
            <span onClick={() => gotoPage(c.pageNo)}>{c.title}</span>
          </li>
        ));

        return (
          <li className={`hasChild ${openAccordion === i ? 'open' : ''}`} key={`d_${i}`}>
            <span className="title" onClick={() => toggleAccordion(i)}>
              {m.title}
            </span>
            <span className="accordion-arrow" onClick={() => toggleAccordion(i)}>
              {svgIcon('ico-arrowRight')}
            </span>
            <ul className={`accordion-child ${openAccordion === i ? 'open' : ''}`}>
              <li>
                <span onClick={() => gotoPage(m.pageNo)}>{m.title}</span>
              </li>
              {children}
            </ul>
          </li>
        );
      } else {
        return (
          <li key={m.pageNo}>
            <span className="title" onClick={() => gotoPage(m.pageNo)}>
              {m.title}
            </span>
          </li>
        );
      }
    });
  };

  const sidebarClass = props.isSidebarOpen ? 'app-sidebar open' : 'app-sidebar';
  const SidenavClass = props.isSidebar? "Sidenav-product-drawer" :""
  return (
    <div className={sidebarClass + " " + SidenavClass}>
      <div className="scrollable-list privacyCookieSidebarMain">
        <ul className="accordion">{renderMenu()}</ul>
        {props.cookiePolicyData ? (
          <ul className="accordion forPrivacyPolicyMain">
            <li>
              <span className="title">
                <Link to="/privacy">Privacy Policy</Link>
              </span>
            </li>
            <li>
              <span className="title">
                <Link to="/cookie">Cookie Policy</Link>
              </span>
            </li>
          </ul>
        ) : null}
      </div>
    </div>
  );
};

export default Sidebar;


// old sidbar before submenu uncomment this


// new sidebar start for subchild menu

// import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
// import { svgIcon } from '../../Functions';
// import BottomNavigation from './bottom-navigation'


// class Sidebar extends Component {
//     toggleAccordion(e) {
//         let parent = e.currentTarget.parentNode;
//         // // -> Disabled auto close accordion
//         // let allItems = e.currentTarget.parentNode.parentNode.children;
//         // for(let i=0; i < allItems.length; i++) {
//         //     let currentItem = allItems[i];
//         //     if(currentItem && currentItem.classList.contains("open")) currentItem.classList.remove("open");
//         // }
//         parent.classList.toggle("open");
//     }

//     toggleSubAccordion = (e) => {
//         let parent = e.currentTarget.parentNode;
//         parent.classList.toggle("open");
//     }

//     gotoPage(pageNo) {
//         const { history, pdf } = this.props;
//         const { pdfSlug } = pdf;

//         pageNo = Number(pageNo);
//         let Url = `/${pdfSlug}/page/${pageNo}`;

//         history.push(Url);

//         this.props.toggleSidebar();
//     }

//     _render = () => {
//         const { menuData } = this.props;
//         if (!menuData || typeof menuData !== "object") return null;
//         const menu = menuData.map((m, i) => {

//             if (m.children && m.children.length > 0) {
//                 let children = m.children.map((c, j) => {
//                     if (c.children && c.children.length > 0) {
//                         let subChild = c.children.map((subData, index) => {
//                             return (
//                                 <li key={`subc_${index}_${j}`}><span onClick={() => this.gotoPage(subData.pageNo)}>{subData.title}</span></li>
//                             )
//                         })

//                         return (
//                             <li className="hassubChild" key={`subd_${j}`}>
//                                 <span className="title" onClick={this.toggleSubAccordion}>{c.title}</span>
//                                 <span className="accordion-subarrow" onClick={this.toggleSubAccordion}>{svgIcon('ico-arrowRight')}</span>
//                                 <ul className="accordion-sub-child">
//                                     <li><span onClick={() => this.gotoPage(c.pageNo)}>{c.title}</span></li>
//                                     {subChild}
//                                 </ul>
//                             </li>
//                         )
//                     }
//                     else {
//                         return (
//                             <li key={c.pageNo}>
//                                 <span className="title" onClick={() => this.gotoPage(c.pageNo)}>{c.title}</span>
//                             </li>
//                         )
//                     }
//                 });

//                 return (
//                     <li className="hasChild" key={`d_${i}`}>
//                         <span className="title" onClick={this.toggleAccordion}>{m.title}</span>
//                         <span className="accordion-arrow" onClick={this.toggleAccordion}>{svgIcon('ico-arrowRight')}</span>
//                         <ul className="accordion-child">
//                             <li><span onClick={() => this.gotoPage(m.pageNo)}>{m.title}</span></li>
//                             {children}
//                         </ul>
//                     </li>
//                 )

//             }
//             else {
//                 return (
//                     <li key={m.pageNo}>
//                         <span className="title" onClick={() => this.gotoPage(m.pageNo)}>{m.title}</span>
//                     </li>
//                 )
//             }
//         })

//         return menu;
//     }

//     render() {

//         const sidebarClass = this.props.isSidebarOpen ? 'app-sidebar open' : 'app-sidebar';
//         return (

//             <div className={sidebarClass}>
//                 <div className="scrollable-list privacyCookieSidebarMain">
//                     <ul className="accordion">
//                         {this._render()}
//                     </ul>
//                     {
//                         this.props.cookiePolicyData ?
//                             <ul className='accordion forPrivacyPolicyMain'>
//                                 <li>
//                                     <span className='title'><Link to={"/privacy"}>Privacy Policy</Link></span>
//                                 </li>
//                                 <li>
//                                     <span className='title'><Link to={"/cookie"}>Cookie Policy</Link></span>
//                                 </li>
//                             </ul>
//                             :
//                             []
//                     }

//                     {/* <div className='bottom-navigation-mob'>
//                         <BottomNavigation
//                             {...this.props}
//                             pdf={this.props.pdf}
//                             isSidebarOpen={this.props.isSidebarOpen}
//                             toggleGenerate={this.props.toggleGenerate}
//                             hideDownloadHome={false}
//                             togglePopup={this.props.togglePopup}
//                         />
//                     </div> */}
//                 </div>
//             </div>
//         );
//     }

// }


// export default Sidebar;

// new sidebar End for subchild menu