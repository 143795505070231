// import jsPDF from "jspdf";
import { ASSETS_URI } from "../config";
import { getS3ImageSrc, getpdfgeneratesrc } from ".";

// export const getS3ImageSrc = (name) => {
//     if(name){
//       var url = `${ASSETS_URI}/${name}`
//       return url;
//     }else{
//       return ""
//     }
//   };

// export const fetchImage = async (pdf,size=0) => {
//   try {

//     // const pdf = this.props.pdf;

//     if (pdf) {
//       const url = await getpdfgeneratesrc(1, size,pdf); // Define your getpdfgeneratesrc function
//       const parts = url.split('/');

//       return parts

//     }
//   } catch (error) {
//     // Handle any errors that occur during the fetch
//     console.error('Error fetching image source:', error);
//   }
// };

/**==========================================================JSPDF====================================================================== */
// import jsPDF from "jspdf";

export const generatePDFFromImages = async (urldata, images) => {
  const jsPDF = await import("jspdf");
  const pdf = new jsPDF.default();

  const loadImage = (imageUrl) =>
    new Promise((resolve, reject) => {
      const img = new Image();
      img.crossOrigin = "Anonymous";
      img.src = imageUrl;

      img.onload = () => resolve(img);
      img.onerror = reject;
    });

  const renderImageToCanvas = (image) =>
    new Promise((resolve) => {
      const canvas = document.createElement("canvas");
      canvas.width = image.width;
      canvas.height = image.height;
      const ctx = canvas.getContext("2d");
      ctx.drawImage(image, 0, 0, image.width, image.height);
      resolve(canvas);
    });

  for (const imageUrl of images) {
    try {
      const image = await loadImage(
        getS3ImageSrc(
          window.__E_TT_Client.companySlug,
          urldata,
          parseInt(imageUrl),
          "image-1.jpg"
        )
      );
      const canvas = await renderImageToCanvas(image);
      const imageData = canvas.toDataURL("image/jpeg");

      pdf.addImage(
        imageData,
        "JPEG",
        0,
        0,
        pdf.internal.pageSize.getWidth(),
        pdf.internal.pageSize.getHeight(),
        null,
        "FAST" // You can try using "FAST" or "SLOW" compression options
      );
      pdf.addPage();
    } catch (error) {
      console.error("Error processing image:", error);
    }
  }

  pdf.deletePage(pdf.internal.getNumberOfPages());
  pdf.save("images.pdf");
};

/**==========================================================PDF LIB====================================================================== */

// import { PDFDocument,rgb } from "pdf-lib";

// export const generatePDFFromImages = async (urldata, images) => {
//   const pdfDoc = await PDFDocument.create();
//   for (const imageUrl of images) {
//     try {
//       const url = getS3ImageSrc(window.__E_TT_Client.companySlug, urldata, parseInt(imageUrl), "image-1.jpg");

//       const imageBytes = await loadImage(url);

//       const image = await pdfDoc.embedPng(imageBytes);
//       const page = pdfDoc.addPage([image.width, image.height]);
//       const { width, height } = page.getSize();

//       page.drawImage(image, {
//         x: 0,
//         y: 0,
//         width,
//         height,
//       });

//     } catch (error) {
//       console.error("Error loading or embedding an image:", error);
//       // You can choose to skip the image or handle the error as needed.
//     }
//   }


//   const pdfBytes = await pdfDoc.save();
//   saveAsPdf(pdfBytes, "images.pdf"); // You'll need a function to save the PDF, e.g., using FileSaver.js
// };

// const loadImage = (imageUrl) => {
//   return new Promise((resolve, reject) => {
//     fetch(imageUrl)
//       .then((response) => response.arrayBuffer())
//       .then((data) => resolve(data))
//       .catch(reject);
//   });
// };

function saveAsPdf(data, filename) {
  const blob = new Blob([data], { type: "application/pdf" });
  const url = URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = filename;
  a.click();
  URL.revokeObjectURL(url);
}
