import _ from 'lodash';
import Service from './service';
import {
	formatProductData
} from '../Functions';
import {
	PedfVersionRemoveCache,
	updatePdfsOffline
} from './hooks';
import { ASSETS_URI, ECOLLAT_CACHE, HOST, PDF_DB } from '../config';
import { get as getDB } from 'idb-keyval';


export const Api_Header = {
	"content-type": "application/json",
};

const getDataFromCache = async () => {
	let oldPdf = await getDB(PDF_DB).then((res) => res && JSON.parse(res));
	if (oldPdf.length > 0) {
		return oldPdf
	}
	return false
}
async function fetchDataFromCacheOrApi(cacheKey) {
	const cache = await caches.open(ECOLLAT_CACHE);
	const cachedResponse = await cache.match(
		`${HOST}${cacheKey}`,
	);
	// Check if data is available in cache
	if (cachedResponse) {
		return Promise.resolve(cache[cacheKey]);
	}
	return false
}

export const getAllPdf = async (slug = false, saveOffline = true) => {
	if (!slug) return false;
	// Check if running in a service worker context
	if (typeof window === 'undefined' || typeof navigator === 'undefined') {
		console.warn('Function getAllPdf should not be called from a service worker.');
		return false;
	}

	let req;
	let fromCache = false;

	try {
		const response = await Service.get(`/allpagesforimages/pages/${slug}`);
		req = response.data.data;
	} catch (error) {
		console.error('API call failed, attempting to retrieve from cache', error);
		req = await getDataFromCache();
		fromCache = true;
	}

	if ((!req || req.status !== 200) && !navigator.onLine) {
		req = await getDataFromCache();
		fromCache = true;
	}
	if (window.matchMedia('(display-mode: standalone)').matches && req && saveOffline && !fromCache) {
		await updatePdfsOffline(req, slug);
	}
	if (req && saveOffline && !fromCache) {
		await PedfVersionRemoveCache(req, slug)
	}

	return req;
};

export const removeAllCaches = async () => {
	const cacheNames = await caches.keys();

	const deletionPromises = cacheNames.map(async (cacheName) => {
		await caches.delete(cacheName);
	});

	// Wait for all deletion promises to complete
	await Promise.all(deletionPromises);
	localStorage.setItem("isdeleted", window.__E_TT_Client.isDelete)
}

export const getPdfDetails = async (slug) => {

	let com_slug = window.__E_TT_Client.companySlug;
	let validSend;
	if (window.location.hostname === "publish.turnintern.com" || window.location.hostname === "www.publish.ecollat.com") {
		validSend = true
	}
	else {
		validSend = false
	}
	if (!slug) return false;

	const req = await Service.post(`/searchaccordingtoslug/${slug}`, { "publishSlug": true ? slug : undefined, companySlug: com_slug }, {
		headers: Api_Header
	})
		.then(res => res.data.data)
		.catch(e => false);


	// await PedfVersionRemoveCache([req])


	return req;
}


export const getPdfDetailsAnalatics = (slug) => {

	let com_slug = window.__E_TT_Client.companySlug;

	if (!slug) return false;

	const req = Service.post(`/addcollatanalytics/${slug}`, { "publishSlug": true ? slug : undefined, companySlug: com_slug }, {
		headers: Api_Header
	})
		.then(res => res.data.data)
		.catch(e => false);

}



export const getCookieInfoApi = async (clientID) => {

	const req = await Service.post(`/public/getcookieinfo`, {
		clientID: clientID
	}, {
		headers: Api_Header
	})
		.then(res => res.data)
		.catch(e => false);

	return req;
}
export const getPrivacyInfoApi = async (clientID) => {

	const req = await Service.post(`/public/getprivacyinfo`, {
		clientID: clientID
	}, {
		headers: Api_Header
	})
		.then(res => res.data)
		.catch(e => false);

	return req;
}
export const getDownloadTimeApi = async (data) => {

	const req = await Service.post(`public/adddowntimeofpdf`, data, {
		headers: Api_Header
	})
		.then(res => res.data)
		.catch(e => false);

	return req;
}
export const getPdfLinkApi = async (data) => {
	const req = await Service.get(`/public/getsharedlink`, {
		params: {
			cart_id: data.cart_id,
			com_slug: data.com_slug
		},
		headers: Api_Header
	})
		.then(res => res.data)
		.catch(e => false);

	return req;
}
export const getSocialMediaPathApi = async (com_slug) => {

	const req = await Service.post(`/public/getsmdata`, {
		com_slug: com_slug
	}, {
		headers: Api_Header
	})
		.then(res => res.data)
		.catch(e => false);

	return req;
}
export const getCartDynamicFieldApi = async (com_slug) => {

	const req = await Service.post(`/public/getcartfields`, {
		com_slug: com_slug
	}, {
		headers: Api_Header
	})
		.then(res => res.data)
		.catch(e => false);

	return req;
}


export const getPdfLink = async (pdfID, slug) => {
	if (!pdfID || !slug) return false;
	const JsonUri = `${ASSETS_URI}/${slug}/${pdfID}/Links/links.json`;

	const headers = {
		// Add your headers here
		'Content-Type': 'application/json',
		// Add more headers as needed
	};

	try {
		const response = await fetch(JsonUri, {
			method: 'GET', // Set the HTTP method as GET
			headers: headers,
			mode: 'no-cors',
		});

		if (response.ok) {
			const data = await response.json();
			return data;
		} else {
			return false;
		}
	} catch (error) {
		console.error('Fetch error:', error);
		return false;
	}
};



export const getPdfMenu = async (pdfID, slug) => {
	if (!pdfID || !slug) return false;
	const JsonUri = `/getlinks/${pdfID}`;

	if (!navigator.onLine) {
		const data = await fetchDataFromCacheOrApi(JsonUri)
		return data
	}
	return await Service.get(JsonUri)
		.then(({
			data
		}) => data.data)
		.catch(e => false)
}

export const searchInAllPdfs = async (slug, word) => {
	const _searchKey = word.trim().split(" ");
	const _singleWord = 'bindersinglekeywordsearch';
	const _multiWord = 'multitextbinderseacrh';

	const url = `/${_searchKey.length > 1 ? _multiWord : _singleWord}/`;

	const data = {
		company_slug: slug,
		search_text: word
	}

	const res = await Service.post(url, data, {
		headers: Api_Header
	})
		.then(res => res.data.data)
		.catch(e => ({
			error: true,
			message: e.message
		}));

	return res;
}


export const searchInEcollat = async (key, pdfId, pages) => {
	let searchKey = key.trim().split(" ");
	let doubleWordUri = `searchaccordingtotwowords/${pdfId}`;
	let singleWordUri = `singlekeywordsearch/${pdfId}`;
	let URI = `/${searchKey.length > 1 ? doubleWordUri : singleWordUri}/${key}`;

	const payload = await Service.post(URI, {}, {
		headers: Api_Header
	})
		.then(res => res.data.data)
		.catch(e => ({
			error: true,
			message: e.message
		}));

	let searchResult;
	if (!payload && (payload !== false || payload.error)) searchResult = null;

	if (payload[0] && payload[0].coordinates) {
		let results = [];
		payload.map(p => {
			//eslint-disable-next-line
			let r = eval(p.coordinates);
			results = [...results, ...r];

			return true;
		});

		// if (pages !== undefined) {
		// 	const _pages = pages.map(page => Number(page));
		// 	results = _.filter(results, result => _pages.includes(result.page_no));
		// }
		searchResult = _.groupBy(results, p => p.page_no);
	} else searchResult = payload;

	return searchResult;
}


export const generateCustomPdfLink = (data) => {
	if (!data.page_no || data.page_no.length <= 0) return;

	let api_uri = `/createpdf`;

	const request = Service.post(api_uri, data, {
		headers: Api_Header
	})
		.then(res => res.data.data)
		.catch(e => false);

	return request;
}
export const generateCustomPdfAnalatics = (data) => {
	if (!data.pdfID || !data.companySlug) return;

	let api_uri = `/public/clickoncustompdf`;

	const request = Service.post(api_uri, data, {
		headers: Api_Header
	})
		.then(res => res.data.data)
		.catch(e => false);

	return request;
}


export const ClickOnCustomMenuAnalatics = (data) => {
	if (!data.pdfID || !data.companySlug) return;

	let api_uri = `/public/clickoncustommenu`;

	const request = Service.post(api_uri, data, {
		headers: Api_Header
	})
		.then(res => res.data.data)
		.catch(e => false);

	return request;
}


export const getPdfProdcuts = async (pdfID) => {
	if (!pdfID) return false;
	if (!navigator.onLine) {
		const data = await fetchDataFromCacheOrApi(`/public/getproductinfopdf/${pdfID}`)
		return data
	}
	return Service.get(`/public/getproductinfopdf/${pdfID}`)
		.then(({
			data
		}) => {
			if (data && data.length > 0) {
				return _.groupBy(data.map(formatProductData), p => p.page_no)
			}
			return false;
		})
		.catch(e => false);
}

export const submitDownloadForm = async data => {
	const req = await Service.post(`/public/downloadform`, data, {
		headers: Api_Header
	})
		.then(res => res.data)
		.catch(e => false);

	return req;
}


export const submitCartDownloadForm = async data => {
	const req = await Service.post(`/public/cartlead`, data, {
		headers: Api_Header
	})
		.then(res => res.data)
		.catch(e => false);

	return req;
}
















// Api for sending the host name 


export const hostAPI = async (data) => {
	return Service.post(`/getindexvalue`, data, {
		headers: Api_Header
	})
		.then(res => res.data)
		.catch(e => {
			console.error(e);
			return false;
		});
};