import React from "react";
const SVGData = {
  play: (
    <svg
    style={{
      filter:"none"
    }}
      width="87"
      height="87"
      viewBox="0 0 87 87"
      fill="#fff "
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_2448_24335"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="87"
        height="87"
      >
        <rect width="86.8134" height="86.8134" fill="#fff" />
      </mask>
      <g mask="url(#mask0_2448_24335)">
        <path
          d="M38.5234 56.9712L56.1573 45.6674C57.0014 45.1248 57.4234 44.3712 57.4234 43.4066C57.4234 42.442 57.0014 41.6884 56.1573 41.1459L38.5234 29.842C37.6191 29.2392 36.6997 29.1939 35.7652 29.7064C34.8308 30.2188 34.3636 31.0176 34.3636 32.1028V54.7104C34.3636 55.7956 34.8308 56.5944 35.7652 57.1069C36.6997 57.6193 37.6191 57.5741 38.5234 56.9712ZM43.4066 79.5789C38.4028 79.5789 33.7004 78.6293 29.2994 76.7303C24.8985 74.8313 21.0703 72.254 17.8148 68.9985C14.5593 65.743 11.982 61.9148 10.0829 57.5138C8.1839 53.1128 7.23438 48.4104 7.23438 43.4066C7.23438 38.4028 8.1839 33.7004 10.0829 29.2994C11.982 24.8985 14.5593 21.0703 17.8148 17.8148C21.0703 14.5593 24.8985 11.982 29.2994 10.0829C33.7004 8.1839 38.4028 7.23438 43.4066 7.23438C48.4104 7.23438 53.1128 8.1839 57.5138 10.0829C61.9148 11.982 65.743 14.5593 68.9985 17.8148C72.254 21.0703 74.8313 24.8985 76.7303 29.2994C78.6293 33.7004 79.5789 38.4028 79.5789 43.4066C79.5789 48.4104 78.6293 53.1128 76.7303 57.5138C74.8313 61.9148 72.254 65.743 68.9985 68.9985C65.743 72.254 61.9148 74.8313 57.5138 76.7303C53.1128 78.6293 48.4104 79.5789 43.4066 79.5789Z"
          fill="#fff"
        />
      </g>
    </svg>
  ),
};
export default SVGData;
