import React from 'react'
import { Link } from 'react-router-dom'

const Footer = () => {
    return (
        <div className='privacyAndCookieBottom'>
            <Link to={"/privacy"} target="_blank">Privacy Policy</Link>
            <Link to={"/cookie"} target="_blank" className="cookiePolicy">Cookie Policy</Link>
        </div>
    )
}

export default Footer