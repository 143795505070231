import React from 'react';
import Container from './Container';

const Modal = ({
    handleModal,
    multiProducts,
    pdf,
    cartCounterHandler,
    buttonType,
    collectionName,
    collectionDescription
}) => {

    return (
        <div className="modal forOnlyMobileAddToCart">
            <span className="cross" onClick={event => handleModal()} />
            <Container
                multiProducts={multiProducts}
                pdf={pdf}
                cartCounterHandler={cartCounterHandler}
                buttonType={buttonType}
                collectionName={collectionName}
                collectionDescription={collectionDescription}
            />
        </div>
    )
}

export default Modal;