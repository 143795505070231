import cookie from 'react-cookies';
import {
    uuidv4,
    formatResponse
} from './index';
import Service from '../Action/service';
import { async } from 'q';


const CART_SESSION = "__ecollat_userCart_T"


export const checkCartSession = () => {

    if (cookie.load(CART_SESSION)) return cookie.load(CART_SESSION);

    else {

        try {

            let cardID = {
                token: uuidv4(),
                timeStamp: new Date().valueOf()
            };

            cardID = JSON.stringify(cardID);
            cardID = window.btoa(cardID);

            const expires = new Date()
            expires.setDate(Date.now() + 1000 * 60 * 60 * 24 * 14);

            cookie.save(CART_SESSION, cardID, {
                path: '/',
                expires
            })

            return cardID;

        } catch (e) {
            return false;
        }
    }
}


const productHandler = function (PDF) {

    let com_slug = window.__E_TT_Client.companySlug
    this.sessionID = checkCartSession();


    this.AddToCart = async (skuCode, pQunatity, tag) => {
        return await Service.post(`public/addtocart`, {
            sku_code: skuCode,
            pdf_id: PDF.pdfID,
            cart_id: this.sessionID,
            com_slug,
            pQunatity: pQunatity ? pQunatity : 1,
            tag
        })

    }

    this.UpdateQuantity = async (sku_code, quantity) => {
        return await Service.post(`public/updatequantity`, {
            cart_id: this.sessionID,
            sku_code,
            quantity,
            com_slug
        })

    }

    this.DeleteCartItem = async (sku_code) => {
        return await Service.post(`public/deletecartvalue`, {
            cart_id: this.sessionID,
            sku_code,
            com_slug
        })

    }

    this.CartData = async () => {
        return await formatResponse(Service.post(`public/getcart`, {
            cart_id: this.sessionID,
            com_slug
        }))
    }


    this.GetDownloadable = async (sku_code) => {

        const User = window.__E_TT_Client;
        const client = User.companySlug;

        const data= await Service.post(`/public/getallinfobysku`, {

            sku_code: sku_code,
            com_slug: client

        })
        return data.data
    }


    this.PrintCart = async () => {
        return await Service.post(`/public/printcart`, {
            cart_id: this.sessionID,
            com_slug
        }, { responseType: 'blob' })
    }

    this.addtagtoProducts = async (producWithTag) => {
        return await Service.post(`/public/addtagtoproduct`, {
            cart_id: this.sessionID,
            com_slug,
            producWithTag
        }, { responseType: 'blob' })
    }

    this.PrintExcelCart = async () => {
        return await Service.post(`/public/getcartasexcel`, {
            cart_id: this.sessionID,
            com_slug
        }, { responseType: 'blob' })
    }
}


export default productHandler;