import React, { Component } from "react";
import { svgIcon } from "../../../Functions";

const Button = (props) => {
  const clickHandler = (data) => {
    const { isOpen } = props;

    if (window.innerWidth <= 768 && !isOpen) {
      this.props.toggleSideBar(true);
    } else {
      this.props.create();
    }
  };

  const { isOpen, count } = props;
  let divClass = count > 0 ? "genrate-btn" : "genrate-btn disabled";

  const textDownload = (
    <>
      {" "}
      <span>Generate Link </span>
      <span>{svgIcon("ico-pdfGenerate")}</span>
    </>
  );
  const textSelected = <span>Show Selected ({count})</span>;

  return (
    <div className={divClass} onClick={clickHandler}>
      {window.innerWidth <= 768 && true ? textSelected : textDownload}
    </div>
  );
};

export default Button;
