import Axios from "axios";
import { HOST } from "../config";
import { FailerLog } from "../Functions/analytic";

const Service = Axios.create({
    baseURL: HOST,
    headers: {
        "content-type": "application/json",
    }
});
Service.interceptors.response.use(
    (res) => {
        return res;
    },
    async (err) => {
        console.log(err.stack);


        if (err.request.responseURL.startsWith(HOST)) {
            var slug = "";
            var pdfID = 0;
            if (err.request.responseURL.startsWith(HOST + "/searchaccordingtoslug")) {
                slug = JSON.parse(err.config.data).publishSlug
            }
            FailerLog({
                apiurl: err.request.responseURL,
                payload: err.config.data,
                response: JSON.stringify({
                    ...err.response.data,
                    stack: err.stack

                }),
                status: err.response.status,
                slug: slug,
                pdfId:pdfID
            })
        }










        if (err.response && err.response.status === 401) {

        }
        return Promise.reject(err);
    },
);
export default Service;