import React, { useState, useEffect, useRef } from "react";
import { Layout, Header } from "../../App";
import {
  trackPageGoogleAnalytics,
  trackPageGoogleTagManager,
} from "../../Functions/analytics";
import { searchInAllPdfs } from "./../../Action/index";
import _ from "lodash";
import PrivacyPolicyCom from "./PrivacyPolicyCom";
import Footer from "../../App/Footer";

const PrivacyPolicy = ({ pdfs = [], privacyPolicyData }) => {
  const { privacyPolicy, isLink, privacyLink } = privacyPolicyData;
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [showSidebar, setShowSidebar] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [showResult, setShowResult] = useState(false);
  const [showPopup, setShowPopup] = useState({
    isActive: false,
    data: null,
    type: null,
  });
  const [enablePdfGenerate, setEnablePdfGenerate] = useState(false);

  const viewRef = useRef();

  const toggleSidebar = (value) => {
    setShowSidebar((prev) => ({
      showSidebar: typeof value === "boolean" ? value : !prev.showSidebar,
      showSearch: false,
      searchResult: null,
    }));

    setTimeout(() => updateSliderCalc(), 250);
  };

  const toggleSearch = (value, clearSearch) => {
    if (clearSearch) {
      setShowSearch({
        showSearch: false,
        searchResult: null,
        showResult: false,
      });

      setTimeout(() => updateSliderCalc(), 250);
    } else {
      setShowSearch((prev) => ({
        showSearch: typeof value === "boolean" ? value : !prev.showSearch,
        showSidebar: false,
      }));
    }
  };

  const toggleResult = (value) => {
    if (window.innerWidth < window.innerHeight) {
      setShowResult((prev) => ({
        showResult: typeof value === "boolean" ? value : !prev.showResult,
      }));
    }
  };

  const search = async (term) => {
    const companySlug = window.__E_TT_Client.companySlug;
    let searchResult = [];
    let data;
    let allPdfs = pdfs;
    setSearchLoading(true);
    let res = await searchInAllPdfs(companySlug, term);

    for (let i = 0; i < allPdfs.length; i++) {
      let resData = res[allPdfs[i].pdfID];
      let _searchResult;

      if (resData !== undefined && resData[0] && resData[0].coordinates) {
        let results = [];
        resData.map((p) => {
          //eslint-disable-next-line
          let r = eval(p.coordinates);
          results = [...results, ...r];

          return true;
        });

        _searchResult = _.groupBy(results, (p) => p.page_no);
      } else _searchResult = resData;

      data = {
        pdf_id: allPdfs[i].pdfID,
        data: _searchResult,
        pdf: allPdfs[i],
      };

      data.data !== undefined && searchResult.push(data);
    }

    // Assuming handleSearchResult is a function passed via props
    // Adjust it according to your component structure
    // this.context.handleSearchResult(searchResult);
    setSearchLoading(false);
    setShowResult(true);

    setTimeout(() => updateSliderCalc(), 250);
  };

  const updateSliderCalc = () => {
    const { current: view } = viewRef;
    if (view && view.calcSlider) view.calcSlider();
  };

  const togglePopup = (config, closePopup = false) => {
    if (closePopup) {
      let defaultConfig = {
        isActive: false,
        data: null,
        type: null,
      };
      return setShowPopup(defaultConfig);
    }
    setShowPopup(config);
  };

  useEffect(() => {
    // componentDidMount logic
    if (window.__E_TT_Client?.gtm) {
      const _pageName = "home";
      // trackPageGoogleAnalytics(this.props.location.pathname);
      trackPageGoogleTagManager(_pageName);
    }
  }, []); // Empty dependency array means this effect will run once when the component mounts

  if (isLink) window.location.href = privacyLink;

  return (
    <>
      <Layout togglePopup={togglePopup} pdf={pdfs[0]} scrollable>
        <Header
          config={{
            showCart: pdfs[0] && pdfs[0].showCart,
            binderSearch: pdfs[0] && pdfs[0].binderSearch,
          }}
          hasSearch={pdfs[0] && pdfs[0].binderSearch}
          toggleSidebar={toggleSidebar}
          toggleResult={toggleResult}
          toggleSearch={toggleSearch}
          search={search}
          isSidebarOpen={showSidebar}
          isSearchOpen={showSearch}
          searchLoading={searchLoading}
        />
        <PrivacyPolicyCom privacyPolicyData={privacyPolicy} />
        <Footer />
      </Layout>
    </>
  );
};

export default PrivacyPolicy;
