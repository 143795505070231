import React, { useState, useEffect, useRef, useContext } from "react";
import productHandler from "./../Functions/product-handler";
// import { async } from 'q';
import { toast } from "react-toastify";
import productPlaceholder from "../Assets/images/product_placeholder.png";
import CreatableSelect from "react-select/creatable";
import { GlobalSearchContext } from "../Components/Viewer/context";
import SpinnerLoader from "../Common/SpinnerLoader";

const colourStyles = {
  option: (styles, { isFocused }) => {
    return {
      ...styles,
      backgroundColor: isFocused ? "transparent" : null,
      color: "#000",
    };
  },
};

const Container = ({
  multiProducts,
  pdf,
  cartCounterHandler,
  buttonType,
  collectionName,
  collectionDescription,
}) => {
  const [totalPrice, setTotalPrice] = useState(0);
  const [readMore, setReadMore] = useState({});
  // const [tagOptions, setTagOptions] = useState([]);
  const [collectionproductloader, setcollectionproduct] = useState(false);
  const [showMultiProducts, setShowMultiProducts] = useState(multiProducts);
  const [priceValue] = useState(
    localStorage.getItem("uiPriceField") || "Price"
  );
  const [TotalpriceValue] = useState(
    localStorage.getItem("priceValue") || "Total Price"
  );
  const [belowTotalMrp] = useState(localStorage.getItem("belowTotalMrp") || "");
  const cart = useRef();
  const { tagOptions, createTag } = useContext(GlobalSearchContext);

  const handleTagChangeChildProduct = (tag) => {
    setcollectionproduct(true);
    let newMultiProducts = [...multiProducts];
    for (const x in multiProducts) {
      newMultiProducts[x].tag = tag["label"];
    }
    setShowMultiProducts(newMultiProducts);
    setcollectionproduct(false);
  };

  // const createTag = (tag) => {
  //     setTagOptions([...tagOptions, { label: tag, value: '' }])
  // };

  const thead = [
    {
      name: "_img",
      text: "Image",
    },

    {
      name: "_product",
      text: "Product",
    },

    {
      name: "_desc",
      text: "Product Description",
    },

    {
      name: "_qty",
      text: "Qty.",
    },

    {
      name: "_price",
      text: priceValue,
    },
    {
      name: "total_price",
      text: TotalpriceValue,
      BelloWText: belowTotalMrp,
    },

    {
      name: "_action",
      text: " ",
    },
  ];

  const handleReadMore = (id) => {
    setReadMore({
      ...readMore,
      [id]: !readMore[id],
    });
  };

  useEffect(() => {
    cart.current = new productHandler(pdf);
  }, [pdf]);

  useEffect(() => {
    let Total = 0;
    for (const x in multiProducts) {
      Total += multiProducts[x].product_price * multiProducts[x].pQunatity;
    }
    setTotalPrice(Total);
  }, [totalPrice]);

  const addToCart = async (sku_code, name, pQunatity, tag) => {
    try {
      const res = await cart.current.AddToCart(sku_code, pQunatity, tag);
      const error = res.data.success;
      if (!error) {
        toast.success("something went wrong!", {
          className: "toast-style",
        });
      } else {
        toast.success(name + " successfully added!", {
          className: "toast-style",
        });

        cartCounterHandler.fetchCartCounterData(); /**  Comment for now */
      }
    } catch (error) {
      toast.success("something went wrong!", {
        className: "toast-style",
      });
    }
  };

  const handleAllCollectionCart = async () => {
    try {
      for (const x in multiProducts) {
        const res = await cart.current.AddToCart(
          [multiProducts[x]?.sku_code],
          multiProducts[x]?.pQunatity,
          multiProducts[x]?.tag
        );
        const error = res.data.success;

        if (!error) {
          toast.success("something went wrong!", {
            className: "toast-style",
          });
        } else {
          if (x === "0") {
            toast.success("collection added to cart successfully", {
              className: "toast-style",
            });
          }
        }
      }
      cartCounterHandler.fetchCartCounterData();
    } catch (error) {
      toast.success("something went wrong!", {
        className: "toast-style",
      });
    }
  };
  return (
    <>
      {collectionproductloader ? (
        <SpinnerLoader />
      ) : showMultiProducts && showMultiProducts.length > 0 ? (
        <>
          {buttonType === "3" ? (
            <div className="multi-products">
              <div className="collectionDetails">
                <div className="collectionNameAndPrice">
                  <h4>{collectionName}</h4>
                  <h5>Rs. {Number(totalPrice).toFixed(2)}</h5>
                </div>
                <div className="collectionDescAndAddCart">
                  <div className="collectionDesc">
                    <p>{collectionDescription}</p>
                  </div>
                  <button
                    className="add-cart-collection"
                    onClick={handleAllCollectionCart}
                  >
                    Add collection to cart
                  </button>
                </div>
                <div className="CollectionTagParent">
                  <div className="CollectionTagMain">
                    <CreatableSelect
                      // value={{ label: product.tag, value: '' }}
                      allowCreateWhileLoading={true}
                      placeholder="Add Tag"
                      options={tagOptions}
                      styles={colourStyles}
                      onCreateOption={(tag) => createTag(tag)}
                      onChange={(selectedOption) =>
                        handleTagChangeChildProduct(selectedOption)
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="collectionProducts">
                <h4>Collection Products</h4>
              </div>
              <div className="displayNoneMobile">
                <table cellPadding="5">
                  <thead>
                    <tr>
                      {thead.map((th, index) => (
                        <th key={index}>
                          {th.text}
                          {th.BelloWText && (
                            <p className="bellowTextTotalMrp">
                              {th.BelloWText}
                            </p>
                          )}
                        </th>
                      ))}
                    </tr>
                  </thead>

                  <tbody>
                    {showMultiProducts.map((item, index) => (
                      <>
                        <tr key={index}>
                          <td>
                            <span>
                              <img
                                src={item?.product_image || productPlaceholder}
                                alt={item?.product_name}
                              />
                            </span>
                          </td>
                          <td>{item?.product_name}</td>
                          <td>
                            {item?.product_description}
                            <br />
                            {item.sku_code}
                          </td>
                          <td>{item?.pQunatity ? item?.pQunatity : 1}</td>
                          <td>{item?.product_price}</td>
                          <td>
                            {item?.product_price *
                              (item?.pQunatity ? item?.pQunatity : 1)}
                          </td>
                          <td>
                            <button
                              className="add-cart"
                              onClick={(e) =>
                                addToCart(
                                  [item.sku_code],
                                  item.product_name,
                                  item?.pQunatity ? item?.pQunatity : 1,
                                  item?.tag
                                )
                              }
                            >
                              Add to cart
                            </button>
                          </td>
                        </tr>
                        {item?.child_sku?.length > 0 &&
                          item.child_sku.map((item, i) => (
                            <tr key={i}>
                              <td>
                                <span>
                                  <img
                                    src={
                                      item.product_image || productPlaceholder
                                    }
                                    alt={item.product_name}
                                  />
                                </span>
                              </td>
                              <td>{item.product_name}</td>
                              <td>
                                {item.product_description}
                                <br />
                                {item.sku_code}
                              </td>
                              <td>{item?.pQunatity ? item?.pQunatity : 1}</td>
                              <td>{item.product_price}</td>
                              <td>
                                {item?.product_price *
                                  (item?.pQunatity ? item?.pQunatity : 1)}
                              </td>
                              <td>
                                <button
                                  className="add-cart"
                                  onClick={(e) =>
                                    addToCart(
                                      [item.sku_code],
                                      item.product_name,
                                      item?.tag
                                    )
                                  }
                                >
                                  Add to cart
                                </button>
                              </td>
                            </tr>
                          ))}
                      </>
                    ))}
                  </tbody>
                </table>
              </div>

              {/* mobile Cart start */}
              <div className="mobileCartsectionContainerCollection">
                {showMultiProducts?.map((item, index) => {
                  return (
                    <React.Fragment key={index}>
                      <div key={index} className="mobilecartMain">
                        <div className="productImageSec">
                          <div className="productImageCart">
                            <img
                              src={item?.product_image || productPlaceholder}
                              alt={item?.product_name}
                            />
                          </div>
                        </div>
                        <div className="productDetailsSec">
                          <div className="productDetailsCartMain">
                            <div className="productNameCross">
                              <div className="productName">
                                <h3>{item?.product_name}</h3>
                                <>
                                  {item?.product_description?.length > 50 ? (
                                    <>
                                      {!readMore[item?.sku_code] ? (
                                        <p>
                                          {`${item?.product_description?.slice(
                                            0,
                                            50
                                          )}...`}
                                          <b
                                            className="cursorPointer"
                                            onClick={() =>
                                              handleReadMore(item?.sku_code)
                                            }
                                          >
                                            Read more
                                          </b>
                                        </p>
                                      ) : (
                                        <p>
                                          {`${item?.product_description?.slice(
                                            0,
                                            200
                                          )} `}
                                          <b
                                            className="cursorPointer"
                                            onClick={() =>
                                              handleReadMore(item?.sku_code)
                                            }
                                          >
                                            {" "}
                                            Read less
                                          </b>
                                        </p>
                                      )}
                                    </>
                                  ) : (
                                    <p> {item.product_description} </p>
                                  )}
                                </>
                              </div>
                            </div>
                            <div className="productPriceandQuantity">
                              <h3>
                                Qty. {item?.pQunatity ? item?.pQunatity : 1}
                              </h3>
                            </div>
                            <div className="productPriceCart MobilePricePerUnit">
                              <h3>{priceValue}</h3>
                              <p className="marginBottom0">{`${item?.product_price}`}</p>
                            </div>
                            {/* <div className="productPriceCart MobilePricePerUnit">
                                                                    <h3>{TotalpriceValue}</h3>
                                                                    <p className='marginBottom0'>{`${item?.product_price * (item?.pQunatity ? item?.pQunatity : 1)}`}</p>
                                                                </div> */}
                            <div className="productPriceandQuantity">
                              <div className="productPriceCart">
                                <h3 className="marginBottom0">{`${
                                  item?.product_price *
                                  (item?.pQunatity ? item?.pQunatity : 1)
                                }`}</h3>
                                <span>({TotalpriceValue})</span>
                                <br />
                                <span>{belowTotalMrp}</span>
                              </div>
                              <div className="productQuantity">
                                <button
                                  className="add-cart-collection"
                                  onClick={(e) =>
                                    addToCart(
                                      [item.sku_code],
                                      item.product_name,
                                      item?.pQunatity ? item?.pQunatity : 1,
                                      item?.tag
                                    )
                                  }
                                >
                                  Add to cart
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {item?.child_sku?.map((item, i) => (
                        <div key={i} className="mobilecartMain">
                          <div className="productImageSec">
                            <div className="productImageCart">
                              <img
                                src={item?.product_image || productPlaceholder}
                                alt={item?.product_name}
                              />
                            </div>
                          </div>
                          <div className="productDetailsSec">
                            <div className="productDetailsCartMain">
                              <div className="productNameCross">
                                <div className="productName">
                                  <h3>{item?.product_name}</h3>
                                  <>
                                    {item?.product_description?.length > 50 ? (
                                      <>
                                        {!readMore[item?.sku_code] ? (
                                          <p>
                                            {`${item?.product_description?.slice(
                                              0,
                                              50
                                            )}...`}
                                            <b
                                              className="cursorPointer"
                                              onClick={() =>
                                                handleReadMore(item?.sku_code)
                                              }
                                            >
                                              Read more
                                            </b>
                                          </p>
                                        ) : (
                                          <p>
                                            {`${item?.product_description?.slice(
                                              0,
                                              200
                                            )} `}
                                            <b
                                              className="cursorPointer"
                                              onClick={() =>
                                                handleReadMore(item?.sku_code)
                                              }
                                            >
                                              {" "}
                                              Read less
                                            </b>
                                          </p>
                                        )}
                                      </>
                                    ) : (
                                      <p> {item.product_description} </p>
                                    )}
                                  </>
                                </div>
                              </div>
                              <div className="productPriceandQuantity">
                                <h3>
                                  Qty. {item?.pQunatity ? item?.pQunatity : 1}
                                </h3>
                              </div>
                              <div className="productPriceandQuantity">
                                <div className="productPriceCart">
                                  <h3 className="marginBottom0">{`Rs. ${item?.product_price}`}</h3>
                                  <span>({priceValue})</span>
                                </div>
                                <div className="productQuantity">
                                  <button
                                    className="add-cart-collection"
                                    onClick={(e) =>
                                      addToCart(
                                        [item.sku_code],
                                        item.product_name,
                                        item?.pQunatity ? item?.pQunatity : 1,
                                        item?.tag
                                      )
                                    }
                                  >
                                    Add to cart
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </React.Fragment>
                  );
                })}
              </div>
              {/* mobile Cart end */}
            </div>
          ) : (
            <div className="multi-products">
              <div className="displayNoneMobile">
                <table cellPadding="5">
                  <thead>
                    <tr>
                      {thead.map((th, index) => {
                        return (
                          <React.Fragment key={index}>
                            {th.name !== "total_price" && (
                              <th key={index}>{th.text}</th>
                            )}
                          </React.Fragment>
                        );
                      })}
                    </tr>
                  </thead>
                  <tbody>
                    {showMultiProducts.map((item, index) => (
                      <React.Fragment key={index}>
                        <tr key={index}>
                          <td>
                            <span>
                              <img
                                src={item?.product_image || productPlaceholder}
                                alt={item?.product_name}
                              />
                            </span>
                          </td>
                          <td>{item?.product_name}</td>
                          <td>
                            {item?.product_description}
                            <br />
                            {item.sku_code}
                          </td>
                          <td>{item?.pQunatity ? item?.pQunatity : 1}</td>
                          <td>{item?.product_price}</td>
                          <td>
                            <button
                              className="add-cart"
                              onClick={(e) =>
                                addToCart(
                                  [item.sku_code],
                                  item.product_name,
                                  item?.pQunatity ? item?.pQunatity : 1
                                )
                              }
                            >
                              Add to cart
                            </button>
                          </td>
                        </tr>
                        {item?.child_sku?.length > 0 &&
                          item.child_sku.map((item, i) => {
                            return (
                              <tr key={i}>
                                <td>
                                  <span>
                                    <img
                                      src={
                                        item.product_image || productPlaceholder
                                      }
                                      alt={item.product_name}
                                    />
                                  </span>
                                </td>
                                <td>{item.product_name}</td>
                                <td>
                                  {item.product_description}
                                  <br />
                                  {item.sku_code}
                                </td>
                                <td>{item?.pQunatity ? item?.pQunatity : 1}</td>
                                <td>{item.product_price}</td>
                                <td>
                                  <button
                                    className="add-cart"
                                    onClick={(e) =>
                                      addToCart(
                                        [item.sku_code],
                                        item.product_name
                                      )
                                    }
                                  >
                                    Add to cart
                                  </button>
                                </td>
                              </tr>
                            );
                          })}
                      </React.Fragment>
                    ))}
                  </tbody>
                </table>
              </div>

              {/* mobile Cart start */}
              <div className="mobileCartsectionContainer">
                {showMultiProducts?.map((item, index) => {
                  return (
                    <React.Fragment key={index}>
                      <div key={index} className="mobilecartMain">
                        <div className="productImageSec">
                          <div className="productImageCart">
                            <img
                              src={item?.product_image || productPlaceholder}
                              alt={item?.product_name}
                            />
                          </div>
                        </div>
                        <div className="productDetailsSec">
                          <div className="productDetailsCartMain">
                            <div className="productNameCross">
                              <div className="productName">
                                <h3>{item?.product_name}</h3>
                                <>
                                  {item?.product_description?.length > 50 ? (
                                    <>
                                      {!readMore[item?.sku_code] ? (
                                        <p>
                                          {`${item?.product_description?.slice(
                                            0,
                                            50
                                          )}...`}
                                          <b
                                            className="cursorPointer"
                                            onClick={() =>
                                              handleReadMore(item?.sku_code)
                                            }
                                          >
                                            Read more
                                          </b>
                                        </p>
                                      ) : (
                                        <p>
                                          {`${item?.product_description?.slice(
                                            0,
                                            200
                                          )} `}
                                          <b
                                            className="cursorPointer"
                                            onClick={() =>
                                              handleReadMore(item?.sku_code)
                                            }
                                          >
                                            {" "}
                                            Read less
                                          </b>
                                        </p>
                                      )}
                                    </>
                                  ) : (
                                    <p> {item.product_description} </p>
                                  )}
                                </>
                              </div>
                            </div>
                            <div className="productPriceandQuantity">
                              <h3>
                                Qty. {item?.pQunatity ? item?.pQunatity : 1}
                              </h3>
                            </div>
                            <div className="productPriceandQuantity">
                              <div className="productPriceCart">
                                <h3 className="marginBottom0">{`Rs. ${item?.product_price}`}</h3>
                                <span>({priceValue})</span>
                              </div>
                              <div className="productQuantity">
                                <button
                                  className="add-cart-collection"
                                  onClick={(e) =>
                                    addToCart(
                                      [item.sku_code],
                                      item.product_name,
                                      item?.pQunatity ? item?.pQunatity : 1
                                    )
                                  }
                                >
                                  Add to cart
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {item?.child_sku?.length > 0 &&
                        item.child_sku.map((item, i) => (
                          <div key={i} className="mobilecartMain">
                            <div className="productImageSec">
                              <div className="productImageCart">
                                <img
                                  src={
                                    item?.product_image || productPlaceholder
                                  }
                                  alt={item?.product_name}
                                />
                              </div>
                            </div>
                            <div className="productDetailsSec">
                              <div className="productDetailsCartMain">
                                <div className="productNameCross">
                                  <div className="productName">
                                    <h3>{item?.product_name}</h3>
                                    <>
                                      {item?.product_description?.length >
                                      50 ? (
                                        <>
                                          {!readMore[item?.sku_code] ? (
                                            <p>
                                              {`${item?.product_description?.slice(
                                                0,
                                                50
                                              )}...`}
                                              <b
                                                className="cursorPointer"
                                                onClick={() =>
                                                  handleReadMore(item?.sku_code)
                                                }
                                              >
                                                Read more
                                              </b>
                                            </p>
                                          ) : (
                                            <p>
                                              {`${item?.product_description?.slice(
                                                0,
                                                200
                                              )} `}
                                              <b
                                                className="cursorPointer"
                                                onClick={() =>
                                                  handleReadMore(item?.sku_code)
                                                }
                                              >
                                                {" "}
                                                Read less
                                              </b>
                                            </p>
                                          )}
                                        </>
                                      ) : (
                                        <p> {item.product_description} </p>
                                      )}
                                    </>
                                  </div>
                                </div>
                                <div className="productPriceandQuantity">
                                  <div className="productPriceCart">
                                    <h3 className="marginBottom0">{`Rs. ${item?.product_price}`}</h3>
                                    <span>({priceValue})</span>
                                  </div>
                                  <div className="productQuantity">
                                    <button
                                      className="add-cart-collection"
                                      onClick={(e) =>
                                        addToCart(
                                          [item.sku_code],
                                          item.product_name,
                                          item?.pQunatity ? item?.pQunatity : 1
                                        )
                                      }
                                    >
                                      Add to cart
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                    </React.Fragment>
                  );
                })}
              </div>
              {/* mobile Cart end */}
            </div>
          )}
        </>
      ) : (
        <h3 style={{ textAlign: "center" }}> No Items</h3>
      )}
    </>
  );
};

export default Container;
