import React, { useState, useEffect, useContext } from "react";
import ProductDrawer from "./product-drawer";
import classnames from "classnames";
import { Overlay } from "../Containers";
import { CartContext } from "./../Components/Viewer/context";

const Layout = ({
  children,
  scrollable,
  whiteBG,
  togglePopup,
  pdf,
  isSidebar,
  embedConfig
}) => {
  const [isOpenSidebar, setIsOpenSidebar] = useState(false);
  const cartContext = useContext(CartContext);
  const toggleCart = (status) => {
    if (typeof status !== "undefined") {
      setIsOpenSidebar(status);
    } else {
      setIsOpenSidebar(!isOpenSidebar);
    }
  };

  const closeCart = () => {
    setIsOpenSidebar(false);
  };

  const layoutTheme = classnames({
    layout: true,
    scroll: !!scrollable,
    "no-scroll": !scrollable,
    "bg-white": !!whiteBG,
    "bg-transparent": !!embedConfig?.isTransparent
  });

  useEffect(() => {
    const handleClickOutside = () => {
      toggleCart(false);
    };

    window.addEventListener("click", handleClickOutside);

    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const newProps = {
    toggleCart,
  };

  const renderChildren = React.Children.map(children, (child) => {
    const componentProps = typeof child.type === "function" ? newProps : {};
    return React.cloneElement(child, componentProps);
  });

  return (
    <div className={layoutTheme}>
      {renderChildren}
      <Overlay
        customClass="inner-overlay"
        isLoading={isOpenSidebar}
        closeCart={closeCart}
      />
      <ProductDrawer
        isSidebar={isSidebar}
        pdf={pdf}
        togglePopup={togglePopup}
        isOpen={isOpenSidebar}
        cartCounterHandler={cartContext}
        closeCart={closeCart}
      />
    </div>
  );
};

export default Layout;
