/* eslint-disable react-hooks/exhaustive-deps */
import _ from "lodash";
import React, { useEffect, useState } from "react";
import classHandler from "classnames";
import { productHelper, svgIcon } from "../Functions";
import { toast } from "react-toastify";
import productPlaceholder from "../Assets/images/product_placeholder.png";
import CreatableSelect from "react-select/creatable";
import { APP_DOMAIN } from "../config";
import { useContext } from "react";
import { GlobalSearchContext } from "../Components/Viewer/context";
import SpinnerLoader from "../Common/SpinnerLoader";

const RemoveIcon = APP_DOMAIN + "/images/remove.svg";
const AddIcon = APP_DOMAIN + "/images/add.svg";

const tableHeader = [
  {
    name: "_img",
    text: "Image",
  },

  {
    name: "product_name",
    text: "Product Name",
  },
  {
    name: "tag_name",
    text: "Tag",
  },
  {
    name: "sku_code",
    text: "Sku Code",
  },

  {
    name: "_desc",
    text: "Product Description",
  },

  {
    name: "qty",
    text: "Qty.",
  },

  {
    name: "price",
    text: "Price",
  },

  // {
  //     name: 'delete',
  //     text: ' ',
  // }
];

const colourStyles = {
  option: (styles, { isFocused }) => {
    return {
      ...styles,
      backgroundColor: isFocused ? "transparent" : null,
      color: "#000",
    };
  },
};

const ProductDrawer = ({
  isOpen,
  cartCounterHandler,
  closeCart,
  togglePopup,
  pdf,
  isSidebar
}) => {
  const [cardloading, setcardloading] = useState(false);
  const [cartData, setCartData] = React.useState([]);
  const [PRODUCT, setProduct] = React.useState({});
  const [readMore, setReadMore] = useState({});
  // const [tagOptions, setTagOptions] = useState([]);
  const [isClearConfirmationModalOpen, setIsClearConfirmationModalOpen] =
    useState(false);
  const [isExcel] = useState(localStorage.getItem("isExcel"));
  const [priceValue] = useState(localStorage.getItem("priceValue") || "Price");
  const [belowTotalMrp] = useState(localStorage.getItem("belowTotalMrp") || "");
  const { tagOptions, createTag } = useContext(GlobalSearchContext);

  const handleReadMore = (id) => {
    setReadMore({
      ...readMore,
      [id]: !readMore[id],
    });
  };

  useEffect(() => {
    if (pdf?.excel !== undefined) {
      localStorage.setItem("isExcel", pdf?.excel);
    }
  }, [pdf, isExcel]);

  // const createTag = (tag, index) => {
  //     setTagOptions(prevState => [...prevState, { label: tag, value: '' }])
  // }
  const handleTagChange = (tag, index) => {
    setcardloading(true);
    let newCartItem = [...cartData];
    newCartItem[index].tag = tag["label"];
    setCartData(newCartItem);
    // setcardloading(false);
    setcardloading(false);
  };

  const fetchCartData = async () => {
    setcardloading(true);
    const { data, error } = await PRODUCT.CartData();
    if (!error) setCartData(data);
    // setcardloading(false);

    setcardloading(false);
  };

  const onQuantityUpdate = async (qty, sku_code, product_name) => {
    // if (qty < 1) return; //old
    // new start
    setcardloading(true);
    if (qty < 1) {
      events.handleDelete(sku_code, product_name);
    }
    // new start
    try {
      // const res = await _.throttle(PRODUCT.UpdateQuantity, 3000)(sku_code, qty);
      const _cartData = cartData.map((item) => {
        if (item.sku_code === sku_code) {
          item.quantity = qty;
        }

        return item;
      });

      setCartData(_cartData);
      setcardloading(false);

      setcardloading(false);
    } catch (error) {
      setcardloading(false);
    }
  };

  const onProductDelete = async (sku_code, name) => {
    try {
      const res = await PRODUCT.DeleteCartItem(sku_code);
      const error = res.data.error;

      if (error) {
        toast.success("something went wrong!", {
          className: "toast-style",
        });
      } else {
        toast.success(name + " successfully deleted!", {
          className: "toast-style",
        });

        cartCounterHandler.fetchCartCounterData();
      }
    } catch (error) {
      toast.success("something went wrong!", {
        className: "toast-style",
      });
    }

    fetchCartData();
  };

  React.useEffect(() => {
    setProduct(new productHelper(null));
  }, []);

  React.useEffect(() => {
    if (Object.keys(PRODUCT).length <= 0) return;
    fetchCartData();
  }, [isOpen]);

  const containerClass = classHandler({
    "product-drawer": true,
    "is-open": isOpen,
    "Sidenav-product-drawer" : isSidebar
  });

  const events = {
    handleQuantity: onQuantityUpdate,
    handleDelete: onProductDelete,
  };

  const enablePopup = (type, data = {}) => {
    let popupConfig = {
      isActive: true,
      data,
      type,
    };

    togglePopup(popupConfig);
  };

  const printHanler = async () => {
    if (cartData[0]?.isTag) {
      let producWithTag = cartData?.map((data) => {
        return { sku_code: data?.sku_code, tag: data?.tag ? data?.tag : "" };
      });
      let _result = await PRODUCT.addtagtoProducts(producWithTag);
      if (_result.status === 200) {
        let pdfUrl = await PRODUCT.PrintCart();
        const data = {
          url: pdfUrl.data,
          clientID: pdf.clientID,
        };

        const popupConfig = {
          isActive: true,
          data,
          type: "cartDownload",
          closeCart: closeCart,
        };

        if (cartData[0].leadForm) {
          enablePopup("cartDownload", popupConfig);
        } else {
          let a = document.createElement("a");
          a.href = window.URL.createObjectURL(pdfUrl.data);
          // Give filename you wish to download
          a.download = "cart.pdf";
          a.style.display = "none";
          document.body.appendChild(a);
          a.click();
          toast.success("successfully download!", {
            className: "toast-style",
          });
        }
      }
    } else {
      let pdfUrl = await PRODUCT.PrintCart();

      const data = {
        url: pdfUrl.data,
        clientID: pdf.clientID,
      };

      const popupConfig = {
        isActive: true,
        data,
        type: "cartDownload",
        closeCart: closeCart,
      };

      if (cartData[0].leadForm) {
        enablePopup("cartDownload", popupConfig);
      } else {
        let a = document.createElement("a");
        a.href = window.URL.createObjectURL(pdfUrl.data);
        // Give filename you wish to download
        a.download = "cart.pdf";
        a.style.display = "none";
        document.body.appendChild(a);
        a.click();
        toast.success("successfully download!", {
          className: "toast-style",
        });
      }
    }
  };
  const printExcelHanler = async () => {
    if (cartData[0]?.isTag) {
      let producWithTag = cartData?.map((data) => {
        return { sku_code: data?.sku_code, tag: data?.tag ? data?.tag : "" };
      });
      let _result = await PRODUCT.addtagtoProducts(producWithTag);
      if (_result.status === 200) {
        let pdfUrl = await PRODUCT.PrintExcelCart();
        let fileName = pdfUrl?.headers["content-disposition"]?.split("=");
        const data = {
          url: pdfUrl.data,
          clientID: pdf.clientID,
        };

        const popupConfig = {
          isActive: true,
          data,
          type: "cartDownload",
          closeCart: closeCart,
        };
        if (cartData[0].leadForm) {
          enablePopup("cartDownload", popupConfig);
        } else {
          let a = document.createElement("a");
          a.href = window.URL.createObjectURL(pdfUrl.data);
          a.download = `${fileName[1]}`;
          a.style.display = "none";
          document.body.appendChild(a);
          a.click();
          toast.success("successfully download!", {
            className: "toast-style",
          });
        }
      }
    } else {
      let pdfUrl = await PRODUCT.PrintExcelCart();
      let fileName = pdfUrl?.headers["content-disposition"]?.split("=");
      const data = {
        url: pdfUrl.data,
        clientID: pdf.clientID,
      };

      const popupConfig = {
        isActive: true,
        data,
        type: "cartDownload",
        closeCart: closeCart,
      };
      if (cartData[0].leadForm) {
        enablePopup("cartDownload", popupConfig);
      } else {
        let a = document.createElement("a");
        a.href = window.URL.createObjectURL(pdfUrl.data);
        // Give filename you wish to download
        // a.download = "cart.pdf";
        a.download = `${fileName[1]}`;
        a.style.display = "none";
        document.body.appendChild(a);
        a.click();
        toast.success("successfully download!", {
          className: "toast-style",
        });
      }
    }
  };

  const handleOpenConfirm = () => {
    setIsClearConfirmationModalOpen(!isClearConfirmationModalOpen);
  };
  const CloseConfirmModal = () => {
    setIsClearConfirmationModalOpen(false);
  };

  const handleClearCart = async () => {
    try {
      for (const x in cartData) {
        const res = await PRODUCT.DeleteCartItem(cartData[x].sku_code);
      }
      cartCounterHandler.fetchCartCounterData();
      fetchCartData();
      setIsClearConfirmationModalOpen(false);
    } catch (error) {
      toast.success("something went wrong!", {
        className: "toast-style",
      });
    }
  };

  return (
    <React.Fragment key="5">
      <div className={containerClass} onClick={(e) => e.stopPropagation()}>
        <div className="cartTitleAndCross">
          {/* {cartData && cartData.length > 0 && ( */}
          <h3 className="cartSectionHeading">Your Cart</h3>
          {/* )} */}
          <div className="close-cart-icon" onClick={closeCart}>
            <span children={svgIcon("ico-close")} />
          </div>
        </div>

        {cardloading ? (
          <p className="empty-cart-text">
            <SpinnerLoader />
          </p>
        ) : (
          <>
            {cartData && cartData.length > 0 ? (
              <React.Fragment>
                {/* <h3 className='cartSectionHeading'>Your Cart</h3> */}
                {/* Desktop cart start */}
                <div className="cart-table displayNoneMobile">
                  <table cellPadding="10">
                    <thead>
                      <tr>
                        {/* {
                                            tableHeader.map((th, i) => (
                                                <th key={i} style={{ textAlign: 'center' }}>{th.text}</th>
                                            ))
                                        } */}
                        <th
                          style={{
                            textAlign: "center",
                            width: cartData[0]?.isTag ? "11%" : "15%",
                          }}
                        >
                          Image
                        </th>
                        <th
                          style={{
                            textAlign: "center",
                            width: cartData[0]?.isTag ? "13%" : "15%",
                          }}
                        >
                          Product Name
                        </th>
                        {cartData[0]?.isTag && (
                          <th style={{ textAlign: "center", width: "14%" }}>
                            Tag
                          </th>
                        )}
                        <th
                          style={{
                            textAlign: "center",
                            width: cartData[0]?.isTag ? "11%" : "14%",
                          }}
                        >
                          Sku Code
                        </th>
                        <th
                          style={{
                            textAlign: "center",
                            width: cartData[0]?.isTag ? "27%" : "28%",
                          }}
                        >
                          Product Description
                        </th>
                        <th
                          style={{
                            textAlign: "center",
                            width: cartData[0]?.isTag ? "8%" : "11%",
                          }}
                        >
                          Qty
                        </th>
                        <th
                          style={{
                            textAlign: "center",
                            width: cartData[0]?.isTag ? "16%" : "17%",
                          }}
                        >
                          {priceValue}
                          {belowTotalMrp !== "" && (
                            <p className="bellowTextTotalMrp">
                              {belowTotalMrp}
                            </p>
                          )}
                        </th>
                        {/* <th style={{ textAlign: 'center', width: cartData[0]?.isTag ? '4%' : '5%' }}></th> */}
                      </tr>
                    </thead>

                    <tbody>
                      {cartData &&
                        cartData.map((item, _index) => (
                          <>
                            {/* <ProductItem itemData={item} key={_index} events={events} /> */}
                            <React.Fragment key={_index}>
                              <tr key={_index}>
                                <td>
                                  {/* <img alt={item.product_name} /> */}

                                  {/* <div> */}
                                  <img
                                    src={item.product_image}
                                    alt={item.product_name}
                                  />
                                  {/* </div> */}
                                </td>

                                <td style={{ textAlign: "center" }}>
                                  {item.product_name}
                                </td>
                                {item?.isTag && (
                                  <td>
                                    <CreatableSelect
                                      value={{ label: item.tag, value: "" }}
                                      allowCreateWhileLoading={true}
                                      options={tagOptions}
                                      styles={colourStyles}
                                      onCreateOption={(tag) =>
                                        createTag(tag, _index)
                                      }
                                      onChange={(selectedOption) =>
                                        handleTagChange(selectedOption, _index)
                                      }
                                    />
                                  </td>
                                )}
                                <td style={{ textAlign: "center" }}>
                                  <p>{item.sku_code}</p>
                                </td>
                                <td style={{ textAlign: "center" }}>
                                  {/* <p> {item.product_description} </p> */}
                                  <>
                                    {item?.product_description?.length > 50 ? (
                                      <>
                                        {!readMore[item?.sku_code] ? (
                                          <p>
                                            {`${item?.product_description?.slice(
                                              0,
                                              50
                                            )}...`}
                                            <b
                                              className="cursorPointer"
                                              onClick={() =>
                                                handleReadMore(item?.sku_code)
                                              }
                                            >
                                              Read more
                                            </b>
                                          </p>
                                        ) : (
                                          <p>
                                            {`${item?.product_description?.slice(
                                              0,
                                              200
                                            )} `}
                                            <b
                                              className="cursorPointer"
                                              onClick={() =>
                                                handleReadMore(item?.sku_code)
                                              }
                                            >
                                              {" "}
                                              Read less
                                            </b>
                                          </p>
                                        )}
                                      </>
                                    ) : (
                                      <p> {item.product_description} </p>
                                    )}
                                  </>
                                </td>

                                <td>
                                  <p key={item.quantity}>
                                    {/* <input
                                                                type="number"
                                                                // defaultValue={itemData.quantity || 1}
                                                                value={item.quantity}
                                                                className="item-quanity"
                                                                min={1}
                                                                onChange={({ currentTarget }) => events.handleQuantity(currentTarget.value, item.sku_code)}
                                                            /> */}
                                    <div className="productQuantityMain">
                                      <div className="cartRemoveImgDiv">
                                        <img
                                          src={RemoveIcon}
                                          alt="-"
                                          className="minusFont"
                                          onClick={() =>
                                            events.handleQuantity(
                                              Number(item?.quantity) - 1,
                                              item?.sku_code,
                                              item?.product_name
                                            )
                                          }
                                        />
                                      </div>
                                      <p>{item?.quantity}</p>
                                      <div className="cartRemoveImgDiv">
                                        <img
                                          src={AddIcon}
                                          alt="+"
                                          onClick={() =>
                                            events.handleQuantity(
                                              Number(item?.quantity) + 1,
                                              item?.sku_code
                                            )
                                          }
                                        />
                                      </div>
                                    </div>
                                  </p>
                                </td>

                                <td style={{ textAlign: "center" }}>
                                  <b className="price">
                                    &#8377; {item.quantity * item.product_price}
                                  </b>
                                </td>

                                {/* <td style={{ textAlign: 'center', cursor: 'pointer' }}>
                                                        <span className="clear-item" children={svgIcon('ico-trash')} onClick={() => events.handleDelete(item.sku_code, item.product_name)} />
                                                    </td> */}
                              </tr>
                            </React.Fragment>
                          </>
                        ))}
                    </tbody>
                  </table>
                </div>
                {/* Desktop cart start */}

                {/* mobile Cart start */}
                <div className="mobileCartsection">
                  {cartData?.map((item, index) => {
                    return (
                      <div key={index} className="mobilecartMain">
                        <div className="productImageSec">
                          <div className="productImageCart">
                            <img
                              src={item?.product_image || productPlaceholder}
                              alt={item?.product_name || "..."}
                            />
                          </div>
                        </div>
                        <div className="productDetailsSec">
                          <div className="productDetailsCartMain">
                            <div className="productNameCross">
                              <div className="productName">
                                <h3>{item?.product_name}</h3>
                                <h5>{`(${item?.sku_code})`}</h5>
                                <>
                                  {item?.product_description?.length > 50 ? (
                                    <>
                                      {!readMore[item?.sku_code] ? (
                                        <p>
                                          {`${item?.product_description?.slice(
                                            0,
                                            50
                                          )}...`}
                                          <b
                                            className="cursorPointer"
                                            onClick={() =>
                                              handleReadMore(item?.sku_code)
                                            }
                                          >
                                            Read more
                                          </b>
                                        </p>
                                      ) : (
                                        <p>
                                          {`${item?.product_description?.slice(
                                            0,
                                            200
                                          )} `}
                                          <b
                                            className="cursorPointer"
                                            onClick={() =>
                                              handleReadMore(item?.sku_code)
                                            }
                                          >
                                            {" "}
                                            Read less
                                          </b>
                                        </p>
                                      )}
                                    </>
                                  ) : (
                                    <p> {item.product_description} </p>
                                  )}
                                </>
                                {cartData[0]?.isTag && (
                                  <div className="addTagMobile">
                                    <CreatableSelect
                                      value={{ label: item.tag, value: "" }}
                                      allowCreateWhileLoading={true}
                                      options={tagOptions}
                                      styles={colourStyles}
                                      placeholder="Add Tag"
                                      onCreateOption={(tag) =>
                                        createTag(tag, index)
                                      }
                                      onChange={(selectedOption) =>
                                        handleTagChange(selectedOption, index)
                                      }
                                    />
                                  </div>
                                )}
                              </div>
                              {/* <div className="mobileCartCross">
                                                            <span onClick={() => events.handleDelete(item?.sku_code, item?.product_name)} children={svgIcon('ico-close')} />
                                                        </div> */}
                            </div>
                            <div className="productPriceandQuantity">
                              <div className="productPriceCart">
                                <h3 className="marginBottom0">{`Rs. ${
                                  item?.quantity * item?.product_price
                                }`}</h3>
                                <span>({priceValue})</span>
                                <br />
                                {belowTotalMrp !== "" && (
                                  <span>{belowTotalMrp}</span>
                                )}
                              </div>
                              <div className="productQuantity">
                                <div className="productQuantityMain">
                                  <p
                                    className="minusFont"
                                    onClick={() =>
                                      events.handleQuantity(
                                        Number(item?.quantity) - 1,
                                        item?.sku_code,
                                        item?.product_name
                                      )
                                    }
                                  >
                                    <img src={RemoveIcon} alt="-" />
                                  </p>
                                  <p>{item?.quantity}</p>
                                  <p
                                    onClick={() =>
                                      events.handleQuantity(
                                        Number(item?.quantity) + 1,
                                        item?.sku_code
                                      )
                                    }
                                  >
                                    <img src={AddIcon} alt="-" />
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
                {/* mobile Cart end */}
                <div className="cartData-action">
                  <button
                    type="button"
                    className="btn"
                    children="Download Pdf"
                    onClick={printHanler}
                  />
                  {isExcel === "true" && (
                    <button
                      type="button"
                      className="btn"
                      children="Download Excel"
                      onClick={printExcelHanler}
                    />
                  )}
                  <button
                    type="button"
                    className="btn ClearCartBtn"
                    children="Clear Cart"
                    onClick={() => setIsClearConfirmationModalOpen(true)}
                  />
                  {/* <button type="button" className="btn" children="Email" /> */}
                </div>
              </React.Fragment>
            ) : (
              <p className="empty-cart-text">Your Cart is Empty</p>
            )}
          </>
        )}
      </div>

      {isClearConfirmationModalOpen && (
        <>
          <div className="modal confirmationModal">
            <span className="cross" onClick={CloseConfirmModal} />
            <div className="confirmationBody">
              <p>
                All cart items and tags will get cleared. Are you sure you want
                to go ahead ?
              </p>
              <div className="confirmationActionBtnMain">
                <div className="confirmationActionBtn">
                  <button
                    type="button"
                    className="btn ClearCartBtn"
                    children="Cancel"
                    onClick={CloseConfirmModal}
                  />
                  <button
                    type="button"
                    className="btn ClearCartBtn"
                    children="Clear"
                    onClick={handleClearCart}
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            onClick={CloseConfirmModal}
            className={`modal-backdrop CloseConfirmModal ${
              isClearConfirmationModalOpen ? "show" : "fade"
            }`}
          ></div>
        </>
      )}
    </React.Fragment>
  );
};

export default ProductDrawer;

const ProductItem = ({ itemData, events }) => {
  return (
    // <div className="cart-item">
    //     <h2>{itemData.product_name}
    //         <span className="clear-item" children={svgIcon('ico-trash')} onClick={() => events.handleDelete(itemData.sku_code)} />
    //     </h2>
    //     <div className="row">
    //         <div className="column item-img">
    //             <img src={itemData.product_image} alt={itemData.product_name} />
    //         </div>
    //         <div className="column item-desc">
    //             <p><b>{itemData.sku_code}</b></p>
    //             <p>{itemData.product_description}</p>
    //             <p key={itemData.quantity}>
    //                 <input
    //                     type="number"
    //                     defaultValue={itemData.quantity || 1}
    //                     className="item-quanity"
    //                     min={1}
    //                     onChange={({ currentTarget }) => events.handleQuantity(currentTarget.value, itemData.sku_code)}
    //                 />
    //                 <b className="price">&#8377; {itemData.product_price}</b>
    //             </p>
    //         </div>
    //         <div className="clearfix" />
    //     </div>
    // </div>

    <React.Fragment>
      <tr>
        <td>
          <img
            src={itemData.product_image || productPlaceholder}
            alt={itemData.product_name}
          />
        </td>

        <td style={{ textAlign: "center" }}>{itemData.product_name}</td>
        <td>
          <input type="text" className="item-quanity" name="tagName" />
        </td>
        <td style={{ textAlign: "center" }}>
          <b>{itemData.sku_code}</b>
        </td>
        <td style={{ textAlign: "center" }}>
          {/* <b> {itemData.sku_code} </b> */}
          <p> {itemData.product_description} </p>
        </td>

        <td>
          <p key={itemData.quantity}>
            <input
              type="number"
              // defaultValue={itemData.quantity || 1}
              value={itemData.quantity}
              className="item-quanity"
              min={1}
              onChange={({ currentTarget }) =>
                events.handleQuantity(currentTarget.value, itemData.sku_code)
              }
            />
          </p>
        </td>

        <td style={{ textAlign: "center" }}>
          <b className="price">
            &#8377; {itemData.quantity * itemData.product_price}
          </b>
        </td>

        <td style={{ textAlign: "center", cursor: "pointer" }}>
          <span
            className="clear-item"
            children={svgIcon("ico-trash")}
            onClick={() =>
              events.handleDelete(itemData.sku_code, itemData.product_name)
            }
          />
        </td>
      </tr>
    </React.Fragment>
  );
};
