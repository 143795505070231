import React from 'react'

const CookiePolicyCom = ({ cookiePolicyData }) => {
    return (
        <div className="container">
            <div className="row privacyComMargintop">
            <div className="privacyHeading">
                    <h2>Cookie Policy</h2>
                </div>
                <div dangerouslySetInnerHTML={{ __html: cookiePolicyData }} />
            </div>
        </div>
    )
}

export default CookiePolicyCom