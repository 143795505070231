import React, { useEffect, useState } from 'react';
import './style.css'; // Make sure to import the CSS file for styles
import { svgIcon } from '.';

const Carousel = ({ images, spot, isPopup = false }) => {
    const { isarrows, ispagination, isloop } = spot.meta
    function Slider() {
        const id = document.getElementById(spot.id)
        const carouselSlides = id.querySelectorAll('.slide');
        const btnPrev = id.querySelector('.prev');
        const btnNext = id.querySelector('.next');
        const dotsSlide = id.querySelector('.dots-container');
        let currentSlide = 0;
        const activeDot = function (slide) {
            id.querySelectorAll('.dot').forEach(dot => dot?.classList?.remove('active'));
            id.querySelector(`.dot[data-slide="${slide}"]`)?.classList?.add('active');
        };
        activeDot(currentSlide);

        const changeSlide = function (slides) {
            carouselSlides.forEach((slide, index) => (slide.style.transform = `translateX(${100 * (index - slides)}%)`));
        };
        changeSlide(currentSlide);

        btnNext.addEventListener('click', function () {
            currentSlide++;
            if (carouselSlides.length - 1 < currentSlide) {
                currentSlide = 0;
            };
            changeSlide(currentSlide);
            activeDot(currentSlide);
        });
        btnPrev.addEventListener('click', function () {
            currentSlide--;
            if (0 > currentSlide) {
                currentSlide = carouselSlides.length - 1;
            };
            changeSlide(currentSlide);
            activeDot(currentSlide);
        });

        dotsSlide.addEventListener('click', function (e) {
            if (e.target.classList.contains('dot')) {
                const slide = e.target.dataset.slide;
                changeSlide(slide);
                activeDot(slide);
            }
        });
    };
    useEffect(() => {
        Slider();
    }, [])
    useEffect(() => {
        if (isloop) {
            const interval = setInterval(() => {
                const id = document.getElementById(spot.id)
                const btnNext = id.querySelector('.next');
                btnNext.click()
            }, 5000); // Auto slide every 5 seconds

            return () => clearInterval(interval); // Clear interval on component unmount
        }
    }, [images.length]);

    return (
        <>
            <div className={`slider ${isPopup && "PopupCarousel"}`} id={spot.id}>
                {
                    images?.map((item, index) => {
                        return (
                            <div className="slide">
                                <img src={item.src} alt={`${index}`} onError={(e => e.target.src = "https://assets.ecollat.in/KohlerIndia/461/7/image-0.jpg")} />
                                <div className='carousel-caption'>
                                    <h5>{item.label}</h5>
                                    <span>{item.discription}</span>
                                </div>
                            </div>
                        )
                    })
                }
                {
                    isarrows && (
                        <>
                            <button className="btn-slide prev">  {svgIcon("left-arrow-v2")} </button>
                            <button className="btn-slide next"> {svgIcon("right-arrow-v2")}</button></>
                    )
                }

                {ispagination && (

                    <div className="dots-container">
                        {
                            images?.map((item, index) => {
                                return (
                                    <span className="dot active" data-slide={index}></span>
                                )
                            })
                        }
                    </div>
                )}


            </div>

        </>
    );
};

export default Carousel;
