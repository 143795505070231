/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";

// Components
import List from "./Components/List";
import Viewer from "./Components/Viewer";
import HowItWorks from "./App/how-it-works";
import { useState } from "react";
import { useEffect } from "react";
import {
  getCartDynamicFieldApi,
  getCookieInfoApi,
  getPrivacyInfoApi,
  getSocialMediaPathApi,
} from "./Action";
import PrivacyPolicy from "./Components/Policy/PrivacyPolicy";
import CookiePolicy from "./Components/Policy/CookiePolicy";
import DisableHomePage from "./Components/DisableHomePage/DisableHomePage";
import { Route, Routes, redirect, Navigate, useParams } from "react-router-dom";

const SubDomainRouter = (appProps) => {
  const [cookiePolicyData, setCookiePolicyData] = useState("");
  const [privacyPolicyData, setPrivacyPolicyData] = useState("");
  const [socialMediaLinks, setSocialMediaLinks] = useState({});
  // const [cartDynamicField, setCartDynamicField] = useState({});
  let clientID = appProps?.pdfList[0]?.clientID;
  let com_slug = window.__E_TT_Client.companySlug;
  let isHome = window.__E_TT_Client.isHome;
  const SlugRedirect = () => {
    const params = useParams();
    return <Navigate to={`/${params.pdf_slug}/page/1`} />;
  };
  const getCookiePolicy = async () => {
    try {
      const result = await getCookieInfoApi(clientID);
      if (result?.success) {
        setCookiePolicyData(result?.data);
      }
    } catch (error) {
    }
  };
  const getPrivacyPolicy = async () => {
    try {
      const result = await getPrivacyInfoApi(clientID);
      if (result?.success) {
        setPrivacyPolicyData(result?.data);
      }
    } catch (error) {
    }
  };
  const getSocialMediaPath = async () => {
    try {
      const result = await getSocialMediaPathApi(com_slug);
      setSocialMediaLinks(result?.data);
    } catch (error) {
    }
  };
  const getCartDynamicField = async () => {
    try {
      const result = await getCartDynamicFieldApi(com_slug);
      if (result.success) {
        // setCartDynamicField(result?.data?.priceValue);
        if (result?.data?.priceValue) {
          localStorage.setItem("priceValue", result?.data?.priceValue);
        }
        if (result?.data?.uiPriceField) {
          localStorage.setItem("uiPriceField", result?.data?.uiPriceField);
        }
        if (result?.data?.belowTotalMrp) {
          localStorage.setItem("belowTotalMrp", result?.data?.belowTotalMrp);
        }
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  useEffect(() => {
    getCookiePolicy();
    getPrivacyPolicy();
    getSocialMediaPath();
    getCartDynamicField();
  }, []);
  return (
    <Routes>
      <Route
        path="/home"
        element={() => (!isHome ? redirect("/") : redirect("/homeRedirect"))}
      />

      <Route path="/how-it-works" element={HowItWorks} />
      <Route
        path="/privacy"
        element={<PrivacyPolicy privacyPolicyData={privacyPolicyData} />}
      />
      <Route
        path="/cookie"
        element={<CookiePolicy cookiePolicyData={cookiePolicyData} />}
      />
      <Route path="/homeRedirect" element={<DisableHomePage />} />

      <Route
        path="/:pdf_slug/page/:pageNo"
        element={
          <Viewer
            cookiePolicyData={cookiePolicyData}
            privacyPolicyData={privacyPolicyData}
            socialMediaLinks={socialMediaLinks}
          />
        }
      />

      <Route path="/:pdf_slug/page" element={<SlugRedirect />} />
      <Route path="/:pdf_slug" element={<SlugRedirect />} />

      {/* new for home page disable not isHome */}
      <Route
        path="/"
        element={
          !isHome ? (
            <List
              pdfs={appProps.pdfList}
              cookiePolicyData={cookiePolicyData}
              privacyPolicyData={privacyPolicyData}
              socialMediaLinks={socialMediaLinks}
            />
          ) : (
            <Navigate to="/homeRedirect" replace />
          )
        }
      />
      {/* {!isHome ? (
        <Route
          path="/"
          element={
            <List
              pdfs={appProps.pdfList}
              cookiePolicyData={cookiePolicyData}
              privacyPolicyData={privacyPolicyData}
              socialMediaLinks={socialMediaLinks}
            />
          }
        />
      ) : (
        redirect("/homeRedirect")
      )} */}
      {/* new for home page disable not isHome */}
    </Routes>
  );
};

export { SubDomainRouter };
