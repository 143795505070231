import React, { useEffect, useState } from 'react';
import { svgIcon, isTouchDevice, getImageSrc } from '../../../Functions';
import Button from './button';

const SelectedPDF = (props) => {
    const [divClass, setDivClass] = useState('pdf-selected-sidebar');
    const [divClass2, setDivClass2] = useState(props.count > 0 ? 'genrate-btn' : 'genrate-btn disabled');

    const showSelected = (data) => {
        const { pdf } = props;
        let divClass = 'page-thumb sidebarshown page-thumb-generate';
        if (isTouchDevice()) divClass += ' has-touch';

        return data.map((p, i) => (
            <div className={divClass} key={i}>
                <img src={getImageSrc(p, 2, pdf)} alt={`Page ${p}`} />
                <div className="thumb-overlay">
                    <div className="overlay-bg"></div>
                    <span className="add-page" onClick={() => props.remove(p, true)}>
                        {svgIcon('ico-minus')}
                    </span>
                </div>
            </div>
        ));
    };

    const updateClasses = () => {
        let newDivClass = 'pdf-selected-sidebar';
        if (window.innerWidth <= 768 && props.isOpen) newDivClass += ' open';
        setDivClass(newDivClass);

        let newDivClass2 = props.count > 0 ? 'genrate-btn' : 'genrate-btn disabled';
        setDivClass2(newDivClass2);
    };

    // useEffect to update classes when isOpen or count changes
    useEffect(() => {
        updateClasses();
    }, [props.isOpen, props.count]);

    return (
        <div className={divClass}>
            <span className="close-selectedpdfbar" onClick={props.close}>
                {svgIcon('ico-arrowLeft')}
            </span>

            <div className="selected-page-wrapper">{showSelected(props.sel)}</div>

            <div className="btn-container">
                <div className={divClass2} onClick={() => props.create()}>
                    <span>Generate Link </span>
                    <span>{svgIcon('ico-pdfGenerate')}</span>
                </div>
            </div>
        </div>
    );
};

export default SelectedPDF;
