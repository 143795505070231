import ReactGA from 'react-ga';
import TagManager from 'react-gtm-module'


let enableGoogleAnalytics = false;
const getAnalyticsPropertyId = () => window.__E_TT_Client.ga;
const getGoogleTagManagerId = () => window.__E_TT_Client.gtm;


export const setupGoogleAnalytics = () => {
  const id = getAnalyticsPropertyId();
  if (!id) return;


  ReactGA.initialize(id);
  enableGoogleAnalytics = true

};

export const setupGoogleTagManager = () => {

  const id = getGoogleTagManagerId()
  if (!id) return;

  const gtmID = {
    gtmId: id,
    dataLayerName: 'PageDataLayer'
  }

  TagManager.initialize(gtmID)
}


export const trackPageGoogleTagManager = pageName => {
  const tagManagerArgs = {
    dataLayer: {
      page: pageName
    },
    dataLayerName: 'PageDataLayer'
  }

  TagManager.dataLayer(tagManagerArgs)
}


export const trackPageGoogleAnalytics = (url, title, page) => {
  // const { pathname, search } = window.location;
  // const page = pathname + search;
  if (!enableGoogleAnalytics) return;
  ReactGA.pageview(url);
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    'event': 'virtualPageview',
    'pageUrl': url,
    'pageTitle': `${title?.replace(/ /g, "-")}-${page}`
  });
  // ReactGA.send({ hitType: "pageview", page: url });
};

export const trackEventGoogleAnalytics = (category, action, label) => {
  ReactGA.event({
    category,
    action,
    label,
  })
}